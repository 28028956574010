import { Component, OnInit } from '@angular/core';
import { Vendors } from 'app/models/Vendors';
import { InvoiceService } from 'app/services/invoice.service';
import { Invoice, InvoiceList } from 'app/models/Invoice';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

interface Company {
  Value: string;
  viewValue: string;
}

// interface StockCode {
//   Value: string;
//   viewValue: string;
// }

@Component({
  selector: 'app-expense-form',
  templateUrl: './expense-form.component.html',
  styleUrls: ['./expense-form.component.css']
})

export class ExpenseFormComponent implements OnInit {

  allVendors: Vendors[];
  displayedVendors: Vendors[];
  Vendor: Vendors[];
  selectedValue: number;
  selectedCompany: string;
  total: number = 0;
  vendorForm: FormGroup;
  poForm: FormGroup;
  submitted = false;
  response: InvoiceList;
  error: '';
  invoice_id: number;
  responses: string;
  show: number = 0;

  company : Company[] = [
    {Value : 'ICSS01', viewValue : "ICS"},
    {Value : 'ECDL01', viewValue : "ECDL"}
  ];

  stockCodes : any = null;//string[] = [];
  displayedStockCodes: string[] = [];

  onCompanyChange(){
    console.log("onCompanyChange called: " + this.selectedCompany);
    let needle = "";
    switch(this.selectedCompany) {
      case "ICSS01" :
        needle = "ICS";
        this.displayedStockCodes = this.stockCodes.ics;
        break;
      case "ECDL01" :
        needle = "ECDL";
        this.displayedStockCodes = this.stockCodes.ecdl;
        break;
      default:
        console.error("onCompanyChange - unknown company!");
    }
    this.displayedVendors = this.allVendors.filter( x => x.account_number === needle);
  }

  constructor(
    private invoiceService: InvoiceService,
    private formBuilder: FormBuilder,
    private router: Router) {

    invoiceService.getStockCodes().subscribe(
      res => { 
        console.log("invoiceService.getStockCodes():", res);
        this.stockCodes = res;
        this.displayedStockCodes = res.ics;
      },

      err => { console.log("Couldn't get stock codes!", err);}
    )

    this.poForm = this.formBuilder.group(
      {
        po: ['',[Validators.required]]
      }
    );
    this.vendorForm = this.formBuilder.group(
      {
        Vendors_contact : ['', [Validators.required]],
        Vendors_name : ['', [Validators.required]],
        Vendors_phone1: [''],//['', [Validators.required]],
        Vendors_email : ['', [Validators.required]],
        Vendor_street : ['', [Validators.required]],
        ExchequerRef: [''],
        Vendor_city: [''],//, [Validators.required]],
        // Vendor_country: ['', [Validators.required]]
        Vendor_vendor: ['', [Validators.required]],
      }
    );
  }




  dynamicArray: Array<Invoice> = [];
  newDynamic: any = {};
  ngOnInit(): void {
    this.newDynamic = {id: 1};
    this.dynamicArray.push(this.newDynamic);

    this.loadVendors();
  }

  addRow() {    
    this.newDynamic = {id: this.dynamicArray.length + 1};
    this.dynamicArray.push(this.newDynamic);
    for(var item in this.dynamicArray)
    {
      if(this.dynamicArray[item].description == null)
      {
        this.show = 0;
      }
    }
  }

  onChangeEvent(){
    this.total = 0;
    this.show = 0;
    for(let i = 0; i <this.dynamicArray.length; i++)
    {
      if(this.dynamicArray[i].unit_price > 0 && this.dynamicArray[i].quantity > 0 && this.dynamicArray[i].description.length > 3)
      {
        this.total += this.dynamicArray[i].quantity * this.dynamicArray[i].unit_price; 
        this.show = 1;
      }
      else
      {
        this.show = 0;
      }
    }
  }

  showVendor(){
    this.Vendor = this.displayedVendors.filter(x => x.vendors_id == this.selectedValue);
  }

  addVendor(){
    if(this.vendorForm.invalid){
      return;
    }
    this.submitted = true;

    let vendor: any = {
      Vendors_contact : this.vendorForm.get('Vendors_contact').value,
      Vendors_name : this.vendorForm.get('Vendors_name').value,
      Vendors_phone1: this.vendorForm.get('Vendors_phone1').value,
      Vendors_email : this.vendorForm.get('Vendors_email').value,
      Vendors_zipcode: '',
      Vendor_street : this.vendorForm.get('Vendor_street').value,
      Vendor_add2: '',
      Vendor_city: this.vendorForm.get('Vendor_city').value,
      // Vendor_country: this.vendorForm.get('Vendor_country').value,
      // ExchequerRef: this.vendorForm.get('ExchequerRef').value
      ExchequerAcCode: this.vendorForm.get('ExchequerRef').value,
      //TODO: vendor field: name? value?
    }

    this.invoiceService.AddVendor(vendor).subscribe((data) => {
      let response = "Vendor has been added!"
      this.responses = response;
      this.vendorForm.reset();
      var modal = document.getElementById('id01');
        setTimeout(() => {
          modal.style.display = "none";
          }, 1500);  //2s
        this.loadVendors();
    })
  }

  submit(){
    let invoice: any = {
      vendors_id: this.selectedValue,
      total: this.total,
      company: this.selectedCompany,
      purchaseOrderNumber: this.poForm.get('po').value,
      invoice_details: this.dynamicArray
    };
    console.log("invoice:",invoice);
    // console.log("submit - early return"); return;
    this.invoiceService.AddInvoice(invoice).subscribe((data) => {
      let response = data as InvoiceList
      this.response = response;
      this.invoice_id = this.response.id;
      this.dynamicArray.length = 0;
      this.total = 0;
      this.newDynamic = {id: this.dynamicArray.length + 1};
      this.dynamicArray.push(this.newDynamic);
      this.router.navigate(['/invoices/', this.invoice_id]);
    })
  }

  reset(){
    this.dynamicArray.length = 0;
    this.total = 0;
    this.newDynamic = {id: this.dynamicArray.length + 1};
    this.dynamicArray.push(this.newDynamic);
  }
  
  deleteRow(index) {  
    if(this.dynamicArray.length ==1) {  
        return false;  
    } else {  
        this.dynamicArray.splice(index, 1);
        this.total = 0;
        for(let i = 0; i <this.dynamicArray.length; i++)
        {
          this.total += this.dynamicArray[i].quantity * this.dynamicArray[i].unit_price; 
        }
        return true;  
    }  
  }  

  public loadVendors(){
    this.invoiceService.getVendors().subscribe(res => {
      this.allVendors = res as Vendors[];
      this.displayedVendors = res as Vendors[];
      console.log("Vendors loaded:", this.displayedVendors);
    })
    
  }

  typeOf(x: any): string {
    return typeof(x);
  }

}
