import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from 'app/models/order';
import { OrderService } from 'app/services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { SkillsCard } from 'app/models/SkillsCard';
import { User } from 'app/models/user';
import { ShopOrder } from 'app/models/Shop-order';
import { VendorOrdersStatus } from 'app/models/Vendors';
import { MatDialog } from '@angular/material/dialog';
import { CancellationrequestedComponent } from '../cancellation/cancellationrequested/cancellationrequested.component';
import { Orders_status_histories } from 'app/models/orders_status_histories';
import { MatTableDataSource } from '@angular/material/table';
import { OrderstatushistoryComponent } from './orderstatushistory/orderstatushistory.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  orderStatus: any;
  Comment: string;
  form: FormGroup;
  actionType: string;
  formComments: string;
  formValue: string;
  existingOrder: Order;
  monthlyOrder: ShopOrder;
  order$: Observable<Order>;
  orders_id: number;
  customers_id: number;
  processAndReleaseOrder$: any;
  DotNetPushResponse: string;
  error: '';
  isButtonVisible = true;
  centreID: number;
  ordersID: number;
  total: number;
  skillsCard: SkillsCard;
  userFromApi: User;
  company: string;
  hasBooks = false;
  vendorOrderSent: VendorOrdersStatus;
  button = false;
  commenting = false;
  public tableColumns = ['orders_status','comments']
  public dataSource = new MatTableDataSource<Orders_status_histories>();

  constructor(
    private dialog: MatDialog,
    private orderService: OrderService,
    private avRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
    ) 
    {
      this.formValue = 'Value';
      this.formComments = 'Comments';
      const idParam = 'id';
      if (this.avRoute.snapshot.params[idParam]) {
        this.orders_id = this.avRoute.snapshot.params[idParam];
      }
      this.form = this.formBuilder.group(
        {
          Comments: ['', [Validators.required]],
        }
      )
    }

  ngOnInit(){
    this.loadOrder();
    this.orderService.apiData$.subscribe(data => {
      if(data != null){
        if(data['orders_status']){
          console.log(data['orders_status'] + '\n' + data['comment']);
          this.orderService.setData(null);
          this.loadOrder();
        }
      }
    })
  }

  loadProcessAndReleaseOrder() {
    this.orderService.getProcessAndReleaseOrder(this.orders_id).subscribe((res) =>{
      this.processAndReleaseOrder$ = res;
      this.orderService.PushProcessAndReleaseOrder(res).subscribe((response) => {
        this.DotNetPushResponse = 'Order has been pushed to DotNET System!';
        console.log(response);
      },
      error => {
        this.error = error;
    })
      console.log(this.processAndReleaseOrder$);
    },
    error => {
      this.error = error;
  });
  }

  modelChangeFn(text: string) {
    this.Comment = text;
  }

  AddComment(){
    this.commenting = true;
    this.orderService.AddComment(this.orders_id, this.Comment).subscribe(data => {
      this.order$.subscribe(data => {
        this.dataSource.data = data.orders_status_histories;
        this.Comment = '';
        this.commenting = false;
      })
    })
  }

  InspectOrderRelease() {
    this.orderService.InspectOrderRelease(this.orders_id, this.centreID).subscribe((res) =>{
      this.skillsCard = res;
      this.orderService.setSkillsCardData(res);
      this.router.navigate(['orders/' + this.orders_id + '/skillscard']);
    },
    error => {
      this.error = error;
  });
  }
  
  loadOrder() {
    this.order$ = this.orderService.getOrder(this.orders_id);
    this.order$.subscribe(data => {
      this.dataSource.data = data.orders_status_histories;
      this.centreID = Number(data.customers_ics_id.toString().replace('CID',''));
      if(data.orders_status == 3){
        this.button = true;
      }
    })
  }

  UpdateStatus(){
    this.orderService.setData(this.orders_id);
    this.dialog.open(OrderstatushistoryComponent);
  }

  processCancellation(){
    let order: any = {
      Comments: this.form.get(this.formComments).value
    };
    this.orderService.ProcessOrderCancellation(this.orders_id, order)
    .subscribe((data) => {
      this.form.reset();
      this.loadOrder();
      this.button = false;
      this.dialog.open(CancellationrequestedComponent);
    },
    error => {
      this.error = error;
      this.form.reset();
    });   
  }

  downloadInvoice() {
    this.orderService.downloadInvoice(this.orders_id).subscribe((data)=>{
      var blob = new Blob([data], {type: 'application/pdf'});
      console.log(blob);
      saveAs(blob, "Order_" + this.orders_id + ".pdf");
    },
    err=>{
      console.log(err);
      }
    )
  }
}
