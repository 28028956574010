import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Product } from 'app/models/product';
import { AuthenticationService } from 'app/services/authentication.service';
import { OrderService } from 'app/services/order.service';
import { ProductService } from 'app/services/product.service';

@Component({
  selector: 'app-healthcheck',
  templateUrl: './healthcheck.component.html',
  styleUrls: ['./healthcheck.component.css']
})
export class HealthcheckComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  formBody: string;
  Status: string;
  submitted = false;

  public tableColumns = ['products_name', 'products_price', 'products_id'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<Product>();

  constructor(
    private formBuilder: FormBuilder,
    private configService: OrderService,
    private authenticationService: AuthenticationService, 
    private router: Router,
    private productService: ProductService
  ) {
    this.formBody = 'status';
    this.form = this.formBuilder.group(
      {
        status: ['', [Validators.required]],
      }
    )
   }

  ngOnInit(){
    this.getProductConfig();
    this.configService.getConfig().subscribe(data => {
      this.form.controls[this.formBody].setValue(data['maintenance_mode'])
      this.Status = data['maintenance_mode'];
    });

    if ((this.authenticationService.currentUserValue.email != 'ashraful@ics.ie') && (this.authenticationService.currentUserValue.email != 'john@ics.ie') && (this.authenticationService.currentUserValue.email != 'declan.munroe@ics.ie') && (this.authenticationService.currentUserValue.email != 'maciej.gut@skillsbox.com'))  
    {
      this.router.navigate(['/']);
    }
  }

  getProductConfig(){
    this.productService.getProductsConfig().subscribe(data => {
      this.dataSource.data = data;
    })
  }

  @HostListener('document:click', ['$event'])
  function(event: MouseEvent) {
    var modal = document.getElementById('id03');
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }

  save() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    let configStatus: any = {
      Configuration_value: this.form.get(this.formBody).value
    };
    this.configService.UpdateConfig(configStatus)
    .subscribe((data) => {
      this.Status = data['maintenance_mode'];
    });   
  }

  get status() { return this.form.get(this.formBody); }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

}
