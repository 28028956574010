import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-amfredirect',
  templateUrl: './amfredirect.component.html',
  styleUrls: ['./amfredirect.component.scss']
})
export class AmfredirectComponent implements OnInit {

  currentDate: Date;
  currentMonth: Number;
  currentYear: Number;
  constructor(
  private router: Router
  ) 
  {
    this.currentDate = new Date();
    this.currentMonth = 1 + this.currentDate.getMonth();
    this.currentYear = this.currentDate.getFullYear();
  }
  ngOnInit() {
    this.router.navigate(['amf/' + this.currentMonth + '/' + this.currentYear]);
  }

}
