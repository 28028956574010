import { Component, OnInit } from '@angular/core';
import { TableActionEvent } from 'app/generic-table/generic-table.component';
import { StatsService } from 'app/services/stats.service';

export interface TransactionInProgress{
  OHMCMCode : string;
  thOurRef : string;
  thAcCode : string;
  thAcName : string;
  thTransDate : string;
  thYourRef : string;
  thAltRef : string;
  thUserField1: string;
  thUserField2: string;
  thUserField3: string;
  thUserField4: string;
  thUserField5: string;
  thUserField6: string;
  thUserField7: string;
  thUserField8: string;
  thUserField9: string;
  thUserField10: string;
}

export interface TransactionPosted {
  id: number;
  MCMCode : string;
  thOurRef : string;
  YourRef : string;
  // AltRef : string;
  message : string;
  postedDate : string;
  datePosted: string;
  ExternalID : string;
}

export interface UnitSentToEnlight {
  processed_id : number;
  centre_id : number;
  order_id : number;
  orderitem_id : number;
  quantity : number;
  itemtype : string;
  sentToEnlight : boolean;
  processed_dt : string;
  dateProcessed : string;
}

@Component({
  selector: 'app-exchequer-polling',
  templateUrl: './exchequer-polling.component.html',
  styleUrls: ['./exchequer-polling.component.scss']
})
export class ExchequerPollingComponent implements OnInit {

  transactionsInProgress: TransactionInProgress[];
  refreshTransactionsInProgress = false;

  transactionsPosted: TransactionPosted[];
  refreshTransactionsPosted = false;

  unitsSentToEnlight: UnitSentToEnlight[];
  refreshUnitsSentToEnlight = false;

  ctrl = {
    refreshTransactionsInProgress : true,
    refreshTransactionsPosted : true,
    refreshUnitsSentToEnlight : true
  };

  constructor(private s: StatsService) { }

  ngOnInit(): void {
    this.get("transactionsInProgress");
    this.get("transactionsPosted");
    this.get("unitsSentToEnlight");
  }

  get(id:string) {
    switch(id){
      case "transactionsInProgress":
        this.s.getTransactionsInProgress().subscribe(
          next => {
            console.log({next})
            this.transactionsInProgress = next;
          }
        );
        break;
      case "transactionsPosted":
        this.s.getTransactionsPosted().subscribe(
          next => {
            console.log({next})
            this.transactionsPosted = next;
          }
        );
        break;
      case "unitsSentToEnlight":
        this.s.getUnitsSentToEnlight().subscribe(
          next => {
            console.log({next})
            this.unitsSentToEnlight = next;
          }
        );
        // care only about change of value, not the value itself
        // this.refreshUnitsSentToEnlight = !this.refreshUnitsSentToEnlight;
        break;
        default:
          console.error(`exchequer-polling - unknown id: ${id}`)
    }
  }

  onTableActionUnitsSentToEnlight(e: TableActionEvent) {
    // open corresponding order page
    const orderId: number = this.unitsSentToEnlight.find(x => x.processed_id == e.data.id).order_id;
    window.open(`${location.origin}/orders/${orderId}`);
  }
  onTableActionTransactionsPosted(e: TableActionEvent) {
    let orderId:string = this.transactionsPosted.find(x => x.id == e.data.id).YourRef;
    //only digits (Is it always "S" at the beginning?)
    orderId = orderId.replace(/[^0-9]*/g, '');
    window.open(`${location.origin}/orders/${orderId}`);
  }
  onTableActionRefreshTransactionsInProgress(e: TableActionEvent) {
    // nothing yet
  }


}
