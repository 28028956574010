import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Sales_view } from 'app/models/Sales_view';
import { MatSort } from '@angular/material/sort';
// import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { OrderService } from 'app/services/order.service';
import { Exchequer, OrderItems, ExchequerPush } from 'app/models/ExchequerPush';
import { NgProgressRef, NgProgress } from 'ngx-progressbar';
import { ExchequerResponse } from 'app/models/ExchequerResponse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exchequer',
  templateUrl: './exchequer.component.html',
  styleUrls: ['./exchequer.component.css']
})
export class ExchequerComponent implements OnInit {

  progressRef: NgProgressRef;
  totalValue: number;
  loading = false;
  $response: ExchequerResponse[];
  value: any;
  discount: any;
  valuesToImport: number;
  error = '';
  showContent = false;

  orders: ExchequerPush[];
  exchequer: Exchequer[];
  orderitems: OrderItems[];
  icssalesview: Sales_view[];

  public tableColumns = [
    'cart_order_id', 'product_id', 'altcode', 'order_date', 'trans', 'exchequerName',
    'value', 'unitPrice','quantity','description','stockCode','glCode', 'po', 'expush'
  ];

  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<Sales_view>();
  showData: string[];
  processPostedOrderResponse: Object;
  orders_id: number;
  noData: boolean;
  

  constructor(
    private router: Router,
    private orderService: OrderService, 
    private progress: NgProgress) { }

  ngOnInit(): void {
    this.loadSalesView();
    this.progressRef = this.progress.ref('myProgress');
    this.progressRef.start();
  }

  getValue(event:any) {
    let value = event.target.innerHTML;
    this.orders_id = value.replace(/<\/?.+?>|S/ig, '');
    this.router.navigate(['/orders/', this.orders_id]);
    console.log(this.orders_id);
  };

  public loadSalesView = () => {
    this.orderService.getSalesView().subscribe(res => {
      this.dataSource.data = res as Sales_view[];
      const runningTotal = this.dataSource.data.reduce((accumulator, order) => {
        return +order.value + accumulator;
      }, 0);

      this.totalValue = Math.round(runningTotal);
      
      this.value = this.dataSource.data.filter(order => order.stockCode === "DISCOUNT")

      const runningTotall = this.value.reduce((accumulator, order) => {
        return +order.value + accumulator;
      }, 0);

      this.discount = Math.round(runningTotall);

      this.valuesToImport = this.totalValue - this.discount;
      
      this.progressRef.complete();
      if(this.dataSource.data?.length == 0)
      {
        this.noData = true;
      }
     
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  
  exchequerPush(){
    this.loading = true;

    this.icssalesview = this.dataSource.data;
  
    let orderObjects = []; 

    for(const order of this.icssalesview ) 
    {
      const foundOrder = orderObjects.find(x => x.cart_order_id == order.cart_order_id)

      const { product_id, glCode, stockCode, exchequerName, value, unitPrice, quantity, description, department, company, cc } = order;
      // Restructure into an object 
      const orderItem = { product_id, glCode, stockCode, value, unitPrice, quantity, description, department, company, cc };

      if (foundOrder)
      {
        foundOrder.orderItems.push(orderItem);
      } 
      else 
      {
        orderObjects.push(
        {
          ExchequerAccount : 'ICSS01',
          exchequerName: exchequerName,
          cart_order_id : order.cart_order_id, 
          altcode: order.altcode,
          order_date: order.order_date,
          trans: order.trans,
          orderSource: 'SHOP',
          po: order.po,
          orderItems: [orderItem]
        });
      }
    }
    
    let pushToExchequer = [];
    pushToExchequer = orderObjects;
    this.orderService.exchequerPush(pushToExchequer)
    .subscribe((data) => {
      this.$response = data;
      this.orderService.setData(data);
      this.orderService.processPostedOrders()
      .subscribe((data) => {
        this.processPostedOrderResponse = data;
        console.log(this.processPostedOrderResponse);
        this.router.navigate(['/response']);
      })
      console.log(this.$response);
    },
    error => {
      this.error = error;
  });
}

exchequerPushSingle(event:any){

  this.loading = true;
  let value = event.target.innerHTML;
  this.orders_id = value.replace(/<\/?.+?>|Push/ig, '');
  this.icssalesview = this.dataSource.data.filter(x => x.cart_order_id == this.orders_id.toString());

  let orderObjects = []; 

  for(const order of this.icssalesview ) 
  {
    const foundOrder = orderObjects.find(x => x.cart_order_id == order.cart_order_id)

    const { product_id, glCode, stockCode, exchequerName, value, unitPrice, quantity, description, department, company, cc } = order;
    // Restructure into an object 
    const orderItem = { product_id, glCode, stockCode, value, unitPrice, quantity, description, department, company, cc };

    if (foundOrder)
    {
      foundOrder.orderItems.push(orderItem);
    } 
    else 
    {
      orderObjects.push(
      {
        ExchequerAccount : 'ICSS01',
        exchequerName: exchequerName,
        cart_order_id : order.cart_order_id, 
        altcode: order.altcode,
        order_date: order.order_date,
        trans: order.trans,
        orderSource: 'SHOP',
        po: order.po,
        orderItems: [orderItem]
      });
    }
  }
  
  let pushToExchequer = [];
  pushToExchequer = orderObjects;
  console.log(pushToExchequer);
  this.orderService.exchequerPush(pushToExchequer)
  .subscribe((data) => {
    this.$response = data;
    this.orderService.setData(data);
    this.orderService.processPostedOrders()
    .subscribe((data) => {
      this.processPostedOrderResponse = data;
      console.log(this.processPostedOrderResponse);
      this.router.navigate(['/response']);
      this.loading = false;
    })
    console.log(this.$response);
  },
  error => {
    this.error = error;
});
}
}
