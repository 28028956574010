import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appOnlyDigits]'
})
export class OnlyDigitsDirective {

  constructor(private el: ElementRef) {}

  // @HostListener('mouseenter') onMouseEnter() {
  //   this.highlight('yellow');
  // }

  // @HostListener('mouseleave') onMouseLeave() {
  //   this.highlight(null);
  // }

  @Input() minVal: number;
  @Input() maxVal: number;

  @Output() valueChange = new EventEmitter()

  @HostListener('input', ['$event']) onInputChange(event) {
    
    const originalValue = this.el.nativeElement.value;
    
    //only digits
    let processedValue:string = originalValue.replace(/[^0-9]*/g, '');
    //remove all starting zeros
    processedValue = processedValue.replace(/^0+/, '');//.replace(/^0+(?=\d)/, '');

    const numericValue = parseInt(processedValue, 10);
    if(isNaN(numericValue)) {
      console.log("invalid", numericValue);
    }
    if((numericValue > this.maxVal)) {
      processedValue = this.maxVal.toString();
    }
    if((processedValue.length >= this.minVal.toString().length) && (numericValue < this.minVal)) {
      processedValue = this.minVal.toString();
    }
    

    this.el.nativeElement.value = processedValue;
    this.valueChange.emit(processedValue);

    if ( originalValue !== this.el.nativeElement.value ) {
      event.stopPropagation();
    }

    console.log({originalValue}, {processedValue}, this.minVal, this.maxVal, {numericValue});
  }

  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;
  }

}
