import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';
import { GetAdmin } from 'app/models/Admin';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from 'app/helpers/custom-validator';
import { CustomValidators } from 'app/helpers/customValidators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit {

  loading = false;
  resetPasswordForm: FormGroup;
  addAdmins: FormGroup;
  role: string;
  fname: string;
  lname: string;
  email: string;
  password: string;
  submitted = false;
  error = '';
  $response: any;
  id: number;

  public tableColumns = ['groups_id', 'name', 'email',  'created', ' modified', 'logdate', 'lognum', 'id'];

  @ViewChild(MatSort) sort: MatSort;

  public dataSource = new MatTableDataSource<GetAdmin>();

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private router: Router) 
    {
      this.fname = 'fname';
      this.lname = 'lname';
      this.email = 'email';
      this.resetPasswordForm = this.formBuilder.group(
        {
          currentpassword: ['', Validators.minLength(6)],
          password: ['', Validators.compose([
            Validators.required,
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(6)
          ])
        ],
          confirmpassword: ['', Validators.minLength(6)],
        }, 
        { 
          validator: ConfirmedValidator('password', 'confirmpassword')
        }
      );
      this.addAdmins = this.formBuilder.group(
        {
          fname:['',[Validators.required]],
          lname:['',[Validators.required]],
          email:['',[Validators.required]]
        }
      )
    }

  ngOnInit(): void {
    if ((this.authenticationService.currentUserValue.email != 'ashraful@ics.ie') && (this.authenticationService.currentUserValue.email != 'john@ics.ie') && (this.authenticationService.currentUserValue.email != 'declan.munroe@ics.ie') && (this.authenticationService.currentUserValue.email != 'maciej.gut@skillsbox.com')) 
    {
      this.router.navigate(['/']);
    }
    this.loadAdmins();
    this.loading = true;
  }

  get f() { return this.resetPasswordForm.controls; }

  public loadAdmins = () => {
    this.authenticationService.getAdmins().subscribe(res => {
      this.dataSource.data = res as GetAdmin[];
      this.loading = false;
    });
  }

  getValue(event:any) {
    let value = event.target.innerHTML;
    this.id = value.replace(/<\/?.+?>/ig, '');
    console.log(this.id);
  };

  addAdmin(){
    if(this.addAdmins.invalid){
      return;
    }
    this.submitted = true;
    this.loading = true;
    let admin: any = {
      Admin_groups_id: Number(this.role),
      Admin_firstname: this.addAdmins.get(this.fname).value,
      Admin_lastname: this.addAdmins.get(this.lname).value,
      Admin_email_address: this.addAdmins.get(this.email).value,
      password : 'my@super@secret@password'
    }
    this.authenticationService.AddAdmins(admin)
    .subscribe((data) => {
      let response = data['message'];
      this.$response = response;
      this.addAdmins.reset();
      this.loading = false;
      var modal = document.getElementById('id02');
      setTimeout(() => {
        modal.style.display = "none";
        }, 3000);  //3s
      this.loadAdmins();
    },
    error => {
        this.error = error;
        this.loading = false;
    });
  }

  onSubmit() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
    return;
    }

    this.loading = true;
    let reset: any = {
      username: this.dataSource.data.find(x => x.id == this.id).email,
      password: this.f.password.value,
      confirmpassword: this.f.confirmpassword.value,
    };
    this.authenticationService.resetAdminPassword(reset)
        .subscribe(
          data => {
            let response = data['message'];
            this.$response = response;
            window.location.reload();
            this.loading = false;
          },
          error => {
              this.error = error;
              this.loading = false;
          });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}

