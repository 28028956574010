import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stocksupdated',
  templateUrl: './stocksupdated.component.html',
  styleUrls: ['./stocksupdated.component.scss']
})
export class StocksupdatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
