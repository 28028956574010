import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopOrder } from 'app/models/Shop-order';
import { OrderService } from 'app/services/order.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, AfterViewInit {

  loading = false;
  shopOrders: ShopOrder[];
  shopTotal: number;
  currentMonth: number;
  currentYear: number;
  monthName: string;
  currentDate: Date;

  public dataSource = new MatTableDataSource<ShopOrder>();
  public tableColumns = [
    'last_renewed','renewed', 'renewed_upto', 'organisation','centre_id', 'centreStatus', 'sin_Number'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  Status: string;
  
  constructor(
    private shopOrderService: OrderService,
    private route: ActivatedRoute,
    private router: Router) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit() {
    const year = +this.route.snapshot.paramMap.get('year');
    this.currentYear = year;
    this.loading = true;
    this.shopOrderService.getHistoryicalYearlyAMFOrders(year).subscribe(shopOrders => {
    
    this.shopOrders = shopOrders;
    this.dataSource.data = shopOrders;
    this.loading = false;
    this.shopTotal = shopOrders[0].total_renewed_this_year;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}

