import { Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { ProductService } from 'app/services/product.service';
import { Observable } from 'rxjs';
import { Product } from 'app/models/product';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'app/models/customer';
import { CustomerService } from 'app/services/customer.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UpdateCustomPrice } from 'app/models/UpdateCustomPrice';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../services/shared.service';
import { AlertService } from '../_alert';


@Component({
  selector: 'app-customersproducts',
  templateUrl: './customersproducts.component.html',
  styleUrls: ['./customersproducts.component.css']
})
export class CustomersproductsComponent implements OnInit, AfterViewInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  ordering_customer: number;
  loading = false;
  form: FormGroup;
  products_id: number;
  custom_price: any;
  customer$: Observable<Customer>;
  customers_id: number;
  submitted = false;
  $response: string;
  customers_name: string;

  products$: Observable<Product[]>;
  productAddedTocart: Product[];
  cartItemCount: number = 0;

  public tableColumns = [
    'products_id','products_name','products_price','custom_price','final_price','action','add2cart'];
  
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    public dataSource = new MatTableDataSource<Product>();
  
 

  constructor(
    protected alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder,
    private customerService: CustomerService, 
    private productService: ProductService,
    private avRoute: ActivatedRoute,
    private sharedService:SharedService
    ) 
    {
      this.custom_price = 'price';
      this.form = this.formBuilder.group(
        {
          price: ['', [Validators.required, IsANumber]]
          // price: ['', [Validators.required]]
        }
      );
      const idParam = 'id';
      if (this.avRoute.snapshot.params[idParam]) {
        this.customers_id = this.avRoute.snapshot.params[idParam];
      }
   }

  ngOnInit(): void {
    // if(localStorage.key(2) != null) maciej 20210302
    if(localStorage.getItem('product') != null)
    {
      const length = localStorage.getItem('product').length;
      this.productAddedTocart=JSON.parse(localStorage.getItem('product'));
      if(length < 3)
      {
        localStorage.removeItem('customer');
        localStorage.removeItem('product');
      }
    }
    this.loadCustomer();
    this.loadProducts();
  }

  save(){
    this.submitted = true;
    if(this.form.invalid) {
      return;
    }

    let upDateCustomPrice: UpdateCustomPrice = {
      customers_id: this.customers_id,
      products_id: this.products_id,
      custom_price: this.form.get(this.custom_price).value
    };
    this.productService.updateCustomPrice(upDateCustomPrice)
    .subscribe((data) => {
      let response = data['message'];
      this.$response = response;
      this.loadProducts();
      this.form.reset();
    });
  }

  resetNA(){
    let upDateCustomPrice: UpdateCustomPrice = {
      customers_id: this.customers_id,
      products_id: this.products_id,
      custom_price: null
    };

    this.productService.updateCustomPrice(upDateCustomPrice)
    .subscribe((data) => {
      let response = 'Custom price has been reset.';
      this.$response = response;
      this.loadProducts();
      this.form.reset();
    })
  };

  getValue(event:any) {
    let value = event.target.innerHTML;
    this.products_id = value.replace(/<\/?.+?>/ig, '');
    console.log(this.products_id);
  };

  loadCustomer() {
    this.customer$ = this.customerService.getCustomer(this.customers_id);
    // if(localStorage.key(1) != null) maciej 20210302
    if(localStorage.getItem('customer') != null)
    {
      var key = localStorage.getItem('customer');
      var ordering_customer = JSON.parse(key); 
      this.customerService.getCustomer(ordering_customer).subscribe((res) => {
        this.customers_name = res.customers_firstname +' '+ res.customers_lastname;
      });
    }
  };

  loadProducts() {
    this.loading = true;
    this.productService.getCustomersProducts(this.customers_id)
    .subscribe(res => {
      this.dataSource.data = res as unknown as Product[];
      this.loading = false;
    });
  };

  OnAddCart(product: Product)
  {
    // if(localStorage.key(1) == null) maciej 20210302
    if(localStorage.getItem('customer') == null)
    {
      localStorage.setItem('customer', JSON.stringify(this.customers_id));
      this.productAddedTocart=this.productService.getProductFromCart();
      if(this.productAddedTocart == null)
      {
        this.productAddedTocart=[];
        this.productAddedTocart.push(product);
        this.productService.addProductToCart(this.productAddedTocart);
        console.log('Product added!');
        this.alertService.success('Product Added!', this.options);
      }
      else
      {
        let tempProduct = this.productAddedTocart.find(p => p.products_id == product.products_id);
        if (tempProduct == null)
        {
          this.productAddedTocart.push(product);
          this.productService.addProductToCart(this.productAddedTocart);
          console.log('Temp Product added!');
          this.alertService.success('Product Added!', this.options);
        }
        else
        {
          console.log('Product already exists!');
          this.alertService.info('Product already exists!', this.options);
        }
      }
      this.cartItemCount = this.productAddedTocart.length;
      this.sharedService.updateCartCount(this.cartItemCount);
    }
    else
    {
      var key = localStorage.getItem('customer');
      var ordering_customer = JSON.parse(key); 

      if(ordering_customer != this.customers_id)
      {
        alert('Please clear the cart before placing order for another customer. There are pending cart items for ' + this.customers_name);// + "," + ordering_customer + "," + this.customers_id);
        return;
      }
      else
      {
        if(this.productAddedTocart == null)
        {
          this.productAddedTocart=[];
          this.productAddedTocart.push(product);
          this.productService.addProductToCart(this.productAddedTocart);
          console.log('Product added!');
          this.alertService.success('Product Added!', this.options);
        }
        else
        {
          let tempProduct = this.productAddedTocart.find(p => p.products_id == product.products_id);
          if (tempProduct == null)
          {
            this.productAddedTocart.push(product);
            this.productService.addProductToCart(this.productAddedTocart);
            console.log('Temp Product added!');
            this.alertService.success('Product Added!', this.options);
          }
          else
          {
            console.log('Product already exists!')
            this.alertService.info('Product already exists!', this.options);
          }
        }
        this.cartItemCount = this.productAddedTocart.length;
        this.sharedService.updateCartCount(this.cartItemCount);
      }
    }
  }

  checkout(){
    // if(localStorage.key(1) != null) maciej 20210302
    if(localStorage.getItem('customer') != null)
    {
      this.ordering_customer = JSON.parse(localStorage.getItem('customer'));
      if(this.ordering_customer != this.customers_id)
      {
        this.router.navigate(['/customers/products/cart/' + this.ordering_customer]);
      }
      else// wtf? maciej
      {
        this.router.navigate(['/customers/products/cart/' + this.ordering_customer]);
      }
    }
    else
    {
      alert('Cart is empty. Please add some product to cart.');
      return;
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  };

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
}

export function IsANumber(ctrl: AbstractControl): { [key: string]: boolean } | null {

  if (! ctrl.value) {return null;}
  if (typeof ctrl.value === "number") {return null;}
  if(typeof ctrl.value !== "string") {return { notANumber: true };}

  return isNaN(Number(ctrl.value)) ? { notANumber: true } : null;
}

