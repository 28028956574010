import { Order } from '../models/order';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { PatchDTO } from 'app/models/PatchDTO';
import { Sales_view } from 'app/models/Sales_view';
import { ShopOrder } from 'app/models/Shop-order';
import { ExchequerResponse } from 'app/models/ExchequerResponse';
import { Sales_of_stock } from 'app/models/Sales_of_stock';
import { SkillsCard } from 'app/models/SkillsCard';
import { VendorOrdersStatus } from 'app/models/Vendors';

@Injectable({
  providedIn: 'root',
})
export class OrderService {

  myAppUrl: string;
  myApiUrl: string;
  myInvoiceURL: string;
  amfURL: string;
  icsSalesViewURL: string;
  monthlyShopOrderURL: string;
  processandreleaseorder: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/order/';
    this.myInvoiceURL = 'api/invoice/';
    this.icsSalesViewURL = 'api/ics_sales_view';
    this.monthlyShopOrderURL = 'api/all_orders_with_exchequer';
    this.amfURL = 'api/amf';
    this.processandreleaseorder = 'api/ProcessAndReleaseOrder/';
  }

  private apiData = new BehaviorSubject<ExchequerResponse[]>(null);
  public apiData$ = this.apiData.asObservable();

  private skillscardData = new BehaviorSubject<SkillsCard>(null);
  public skillscard$ = this.skillscardData.asObservable();

  getOrders(): Observable<Order[]> {
    return this.http.get<Order[]>(this.myAppUrl + this.myApiUrl)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  getOrder(orders_id: number): Observable<Order> {
    return this.http.get<Order>(this.myAppUrl + this.myApiUrl + orders_id)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  renewAMF(centre_id: number){
    return this.http.get(this.myAppUrl + this.amfURL + '/renew/'+ centre_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  getProcessAndReleaseOrder(orders_id: number){
    return this.http.get(this.myAppUrl + this.processandreleaseorder + orders_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  UpdateOrderStatus(orders_id: number, status: number){
    return this.http.get(this.myAppUrl + this.myApiUrl + 'status/' + orders_id + '/' + status).pipe(
      map(res => {
        return res;
      })
    );
  }

  //13/12/2021
  updateOrderBcId(orders_id: number, bcId: string) {
    return this.http.get(this.myAppUrl + this.myApiUrl + 'bcid/' + orders_id + '/' + bcId);
  }

  setSkillsCardData(data) { 
    this.skillscardData.next(data)
  }

  PushProcessAndReleaseOrder(order: any){
    return this.http.post('https://cms.ics-skills.net/api/exchequer/ProcessAndReleaseOrder', JSON.stringify(order), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateOrder(orders_id: number, order: any): Observable<Order> {
    return this.http.put<Order>(this.myAppUrl + this.myApiUrl + orders_id, JSON.stringify(order),this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  updateOrder_Status(orders_id: number, patchDTO): Observable<PatchDTO> {
    return this.http.patch<PatchDTO>(this.myAppUrl + this.myApiUrl + orders_id, JSON.stringify(patchDTO),this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  downloadInvoice(orders_id: number){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(this.myAppUrl + this.myInvoiceURL + orders_id, {headers: headers, responseType: 'blob' })
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  Export_Monthly_Orders_CSV(month: number, year: number){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/csv');
    return this.http.get(this.myAppUrl + 'api/all_orders_with_exchequer/csv' + '?monthid=' + month + '&year=' + year, {headers: headers, responseType: 'blob' })
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  Export_Stripe_Payments_CSV(month: number, year: number){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/csv');
    return this.http.get(this.myAppUrl + 'ps/registrants/stripe/csv' + '?monthid=' + month + '&year=' + year, {headers: headers, responseType: 'blob' })
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  Export_Offline_Transactions_CSV(month: number, year: number){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/csv');
    return this.http.get(this.myAppUrl + 'ps/registrants/offline/csv' + '?monthid=' + month + '&year=' + year, {headers: headers, responseType: 'blob' })
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  Export_Sales_of_stock_CSV(month: number, year: number){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/csv');
    return this.http.get(this.myAppUrl + 'api/Sales_of_stock/csv' + '?monthid=' + month + '&year=' + year, {headers: headers, responseType: 'blob' })
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  Export_YearlySales_of_stock_CSV(year: number){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'text/csv');
    return this.http.get(this.myAppUrl + 'api/Sales_of_stock/yearlycsv?year=' + year, {headers: headers, responseType: 'blob' })
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  getSalesView(): Observable<Sales_view[]> {
    return this.http.get<Sales_view[]>(this.myAppUrl + this.icsSalesViewURL)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  exchequerPush(exchequerPush){
    return this.http.post('https://cms.ics-skills.net/api/exchequer/processExchequerPreview', JSON.stringify(exchequerPush), this.httpOptions).pipe(
      map(data => {
        return data as ExchequerResponse[];
      })
    );
  }

  setData(data) { 
    this.apiData.next(data)
  }

  processPostedOrders(){
    // return this.http.get('https://ics-shop-api.azurewebsites.net/api/processpostedorders').pipe(
    return this.http.get(`${environment.apiIcsShop}processpostedorders`).pipe(
      map(res => {
        return res;
      })
    );
  }

  getMonthlyOrders(month: number, year: number): Observable<ShopOrder[]> {
    return this.http.get<ShopOrder[]>(this.myAppUrl + this.monthlyShopOrderURL + '?monthid=' + month + '&year=' + year)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  getMonthlyAMFOrders(month: number, year: number): Observable<ShopOrder[]> {
    return this.http.get<ShopOrder[]>(this.myAppUrl + this.amfURL + '?monthid=' + month + '&year=' + year)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  getCurrentAMFRenewals(){
    return this.http.get(this.myAppUrl + this.amfURL + '/renewals').pipe(
      map(res => {
        return res;
      })
    );
  }

  getHistoryicalYearlyAMFOrders(year: number): Observable<ShopOrder[]> {
    return this.http.get<ShopOrder[]>(this.myAppUrl + this.amfURL + '/history?year=' + year)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  getMonthlyOrder(orders_id: number): Observable<ShopOrder>{
    return this.http.get<ShopOrder>(this.myAppUrl + this.monthlyShopOrderURL +'/' + orders_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  InspectOrderRelease(orders_id: number, centreID: number): Observable<SkillsCard>{
    return this.http.get<SkillsCard>('https://cms.ics-skills.net/api/icsshop/InspectOrderRelease?orders_id=' + orders_id + '&centreId=' + centreID).pipe(
      map(res => {
        return res;
      })
    );
  }

  getSales_of_stock(month: number, year: number): Observable<Sales_of_stock[]> {
    return this.http.get<Sales_of_stock[]>(this.myAppUrl + 'api/Sales_of_stock' + '?monthid=' + month + '&year=' + year)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  getYearlySales_of_stock(year: number): Observable<Sales_of_stock[]> {
    return this.http.get<Sales_of_stock[]>(this.myAppUrl + 'api/Sales_of_stock/yearlyorders?year=' + year)
    .pipe(
      map(res => {
        return res;
      })
    );
  }

  // getYearlyOrders(){
  //   return this.http.get(this.myAppUrl + this.monthlyShopOrderURL + '/yearlyorders?year=2020').pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }

  // maciej - 30032021
  getYearlyOrders(payload : number[]): Observable<number[][]> {
    // let payload: number[] = [2020,2019,2018];
    console.log("order.service getYearlyOrders called with:", payload);
    let query:string = "";
    for(let el of payload) {
      query += `year=${el}&`
    }
    // return this.http.get(this.myAppUrl + this.monthlyShopOrderURL + '/yearlyorders?year=2020&year=2019');
    return this.http.get<number[][]>(this.myAppUrl + this.monthlyShopOrderURL + `/yearlyorders?${query}`);
  }

  getExchequerConfig(){
    return this.http.get(this.myAppUrl + 'api/ExchequerConfig').pipe(
      map(res => {
        return res;
      })
    );
  }

  UpdateExchequerConfig(value: string){
    return this.http.post(this.myAppUrl + 'api/ExchequerConfig', JSON.stringify(value), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  getConfig(){
    return this.http.get(this.myAppUrl + 'api/Config').pipe(
      map(res => {
        return res;
      })
    );
  }

  UpdateConfig(value: string){
    return this.http.post(this.myAppUrl + 'api/Config', JSON.stringify(value), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  getVendorSentStatus(orders_id: number){
    return this.http.get(this.myAppUrl + 'api/orders_shipping/' + orders_id).pipe(
      map(res => {
        return res as VendorOrdersStatus;
      })
    );
  }

  PlaceOrder (orderdetail) {
    return this.http.post(this.myAppUrl + this.myApiUrl + 'adminorder', JSON.stringify(orderdetail), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  AddComment (orders_id: number, order: string) {
    return this.http.post(this.myAppUrl + this.myApiUrl + 'comment/' + orders_id, JSON.stringify(order), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  ProcessOrderCancellation (orders_id: number, order) {
    return this.http.post(this.myAppUrl + this.myApiUrl + 'cancelrequest/' + orders_id, JSON.stringify(order), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  OrderCancellationAccepted (orders_id: number) {
    return this.http.get(this.myAppUrl + this.myApiUrl + 'cancelrequest/' + orders_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  OrderCancellationRejected (orders_id: number) {
    return this.http.get(this.myAppUrl + this.myApiUrl + 'cancelrequest/rejected/' + orders_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  OrderCancelled (orders_id: number) {
    return this.http.get(this.myAppUrl + this.myApiUrl + 'cancelled/' + orders_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  StocksUpdated (orders_id: number) {
    return this.http.get(this.myAppUrl + this.myApiUrl + 'cancelled/stocksupdated' + orders_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = `Error: ${error.message}`;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
