import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Product } from 'app/models/product';
import { ProductService } from 'app/services/product.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, AfterViewInit {

  loading = false;
  newColor = false;
  newColor1 = false;
  newColor2 = false;
  addProduct: FormGroup;
  submitted = false;
  pName: string;
  pDsc: string;
  pPrice: string;
  stockCode: string;
  GL: string;
  CC: string;
  DEP: string;
  error = '';

  public tableColumns = ['products_name', 'products_price', 'products_id'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<Product>();
  $response: Object;

  constructor(private formBuilder: FormBuilder, private productService: ProductService) { 
    this.pName = 'pName';
        this.pDsc = 'pDsc';
        this.pPrice = 'pPrice';
        this.stockCode = 'stockCode';
        this.GL = 'GL';
        this.CC = 'CC';
        this.DEP = 'DEP';
        this.addProduct = this.formBuilder.group(
          {
            pName: ['', [Validators.required]],
            pDsc: ['', [Validators.required]],
            pPrice: ['', [Validators.required]],
            stockCode: ['', [Validators.required]],
            GL: ['', [Validators.required]],
            CC: ['', [Validators.required]],
            DEP: ['', [Validators.required]]
          }
        )
  }

  ngOnInit(): void {
    this.loadProducts();
    this.loading = true;
  }

  AddProduct(){
    if(this.addProduct.invalid) {
      return;
    }
    this.submitted = true;
    this.loading = true;

    let product: any = {
      products_name: this.addProduct.get(this.pName).value,
      products_description: this.addProduct.get(this.pDsc).value,
      products_price: this.addProduct.get(this.pPrice).value,
      exchequerName: this.addProduct.get(this.stockCode).value,
      exchequerDepartment: this.addProduct.get(this.DEP).value,
      exchequerGLcode: this.addProduct.get(this.GL).value,
      exchequerCC: this.addProduct.get(this.CC).value
    };
    this.productService.AddProduct(product)
    .subscribe((data) => {
      let response = data;
      this.$response = "Product has been added Successfully!";
      this.addProduct.reset();
      this.loading = false;
      var modal = document.getElementById('id01');
      setTimeout(() => {
        modal.style.display = "none";
        }, 3000);  //3s
      this.loadProducts();
    },
    error => {
        this.error = error;
        this.loading = false;
    });
  }

  public loadProducts = () => {
    this.loading = true;
    this.newColor1 = false;
    this.newColor2 = false;
    this.productService.getProducts().subscribe(res => {
      this.dataSource.data = res as Product[];
      this.loading = false;
      this.newColor = true;
    });
  }

  public loadInActiveProducts = () => {
    this.loading = true;
    this.newColor = false;
    this.newColor2 = false;
    this.productService.getInActiveProducts().subscribe(res => {
      this.dataSource.data = res as Product[];
      this.loading = false;
      this.newColor1 = true;
    });
  }

  public loadAllProducts = () => {
    this.loading = true;
    this.newColor1 = false;
    this.newColor = false;
    this.productService.getAllProducts().subscribe(res => {
      this.dataSource.data = res as Product[];
      this.loading = false;
      this.newColor2 = true;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
