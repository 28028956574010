import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SkillsCard } from 'app/models/SkillsCard';
import { OrderService } from 'app/services/order.service';
import {Location} from '@angular/common';
import * as jspdf from 'jspdf';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-skillscard',
  templateUrl: './skillscard.component.html',
  styleUrls: ['./skillscard.component.css']
})
export class SkillscardComponent implements OnInit {
  
  panelOpenState = false;

  @ViewChild('content', {static: false}) content: ElementRef; 

  data: SkillsCard;
  orders_id: number;

  constructor(
    private orderService: OrderService,
    private _location: Location,
    private avRoute: ActivatedRoute,
    ) {
    
    const idParam = 'id';
    if (this.avRoute.snapshot.params[idParam]) {
      this.orders_id = this.avRoute.snapshot.params[idParam];
    };
    orderService.skillscard$.subscribe(data => this.data = data)
  }

  ngOnInit(): void {
  }

  back() {
    this._location.back();
  }

  exportAsPDF()
  {
    const doc = new jspdf('l', 'mm', 'a3');
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const content = this.content.nativeElement;
    doc.fromHTML(content.innerHTML, 55, 15, {
      'width': 100,
      'elementHandlers': specialElementHandlers
    });
    doc.save('InspectOrder_' + this.orders_id + '.pdf');
  }

}
