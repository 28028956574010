import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Order } from 'app/models/order';
import { OrderService } from 'app/services/order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, AfterViewInit {

  loading = false;

  public tableColumns = ['customers_ics_id', 'customers_email_address', 'customers_name', 'date_purchased', 'orders_status', 'total_Value', 'orders_id'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<Order>();

  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.loadOrders();
    this.loading = true;
  }

  public loadOrders = () => {
    this.orderService.getOrders().subscribe(res => {
      this.dataSource.data = res as Order[];
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}

