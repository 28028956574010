import { Component, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from 'app/models/customer';
import { CustomerService } from 'app/services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

interface Company {
  Value: boolean;
  viewValue: string;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit, AfterViewInit {

  public tableColumns = ['date_purchased', 'orders_status', 'value' ,'orders_id'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource = new MatTableDataSource<Customer>();

  term;
  selectedCompany: boolean;
  form: FormGroup;
  emailForm: FormGroup;
  email: string;
  updateProfile: FormGroup;
  customer$: Observable<Customer>;
  customers_id: number;
  icsid: any;
  $response: string;
  error = '';
  loading = false;
  submitted = false;
  fname: string;
  lname: string;
  password: string;
  telephone: string;
  address: string;
  company: string;
  suburb: string;
  city: string;
  postcode:string;
  exchequercode: string;
  companies : Company[] = [
    {Value : true, viewValue : "Orders on Account"},
    {Value : false, viewValue : "Orders on Card"}
  ];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private customerService: CustomerService, 
    private avRoute: ActivatedRoute) {
      this.icsid = 'icsid';
      this.exchequercode = 'exchequercode';
      this.form = this.formBuilder.group(
        {
          icsid: ['', [Validators.required]],
          exchequercode: ['', [Validators.required]]
        }
      );
      this.emailForm = this.formBuilder.group(
        {
          email: ['', [Validators.required]]
        }
      );
      this.fname = 'fname';
      this.lname = 'lname';
      this.telephone = 'telephone';
      this.company = 'company';
      this.address = 'address';
      this.suburb = 'suburb';
      this.city = 'city';
      this.postcode = 'postcode';
      this.updateProfile = this.formBuilder.group(
        {
          fname: ['', [Validators.required]],
          lname: ['', [Validators.required]],
          email: ['', [Validators.required]],
          telephone: ['', [Validators.required]],
          company: ['', [Validators.required]],
          address: ['', [Validators.required]],
          suburb: ['', [Validators.required]],
          city: ['', [Validators.required]],
          postcode: ['', [Validators.required]]
        }
      );
      const idParam = 'id';
      if (this.avRoute.snapshot.params[idParam]) {
        this.customers_id = this.avRoute.snapshot.params[idParam];
      };
   }

  ngOnInit(): void {
    this.loadCustomer();
    this.customerService.getCustomer(this.customers_id).subscribe((data) => {
      this.selectedCompany = data.purchased_on_account;
      this.updateProfile.controls[this.fname].setValue(data.customers_firstname);
      this.updateProfile.controls[this.lname].setValue(data.customers_lastname);
      this.updateProfile.controls[this.telephone].setValue(data.customers_telephone);
      this.updateProfile.controls[this.company].setValue(data.company);
      this.updateProfile.controls[this.address].setValue(data.address);
      this.updateProfile.controls[this.suburb].setValue(data.suburb);
      this.updateProfile.controls[this.city].setValue(data.city);
      if(data.post_code === "")
      {
        this.updateProfile.controls[this.postcode].setValue('N/A')
      }
      else
      {
        this.updateProfile.controls[this.postcode].setValue(data.post_code)
      }
    })
  }

  onChangeEvent(value : boolean){
    this.customerService.UpdatePaymentMethod(this.customers_id,value).subscribe((data => {
      console.log(JSON.stringify(data))
    }))
  }

  UpdateProfile(){
    if(this.updateProfile.pristine) {
      return;
    }
    this.submitted = true;

    this.loading = true;
    let customer: any = {
      firstname: this.updateProfile.get(this.fname).value,
      lastname: this.updateProfile.get(this.lname).value,
      telephone: this.updateProfile.get(this.telephone).value,
      company: this.updateProfile.get(this.company).value,
      address: this.updateProfile.get(this.address).value,
      suburb: this.updateProfile.get(this.suburb).value,
      postcode: this.updateProfile.get(this.postcode).value,
      city: this.updateProfile.get(this.city).value
    };
    this.customerService.UpdateProfile(this.customers_id, customer)
    .subscribe((data) => {
      let response = data['message'];
      console.log(response);
      this.loading = false;
      var modal = document.getElementById('id02');
      modal.style.display = "none";
      this.loadCustomer();
      this.loading = false;
    },
    error => {
        this.error = error;
        this.loading = false;
    });
  }
  
  @HostListener('document:click', ['$event'])
  function(event: MouseEvent) {
      var modal = document.getElementById('id02');
      var modal1 = document.getElementById('id01');
      var modal2 = document.getElementById('id03');
      if (event.target == modal) {
        modal.style.display = "none";
      }
      else if (event.target == modal1) {
        modal1.style.display = "none";
      }
      else if (event.target == modal2) {
        modal2.style.display = "none";
      }
  }

  AddIcsId(){
    this.loading = true;
    if(this.form.invalid)
    return;

    let addicsid: any = {
      icsid: Number(this.form.get(this.icsid).value),
      exchequer_code: this.form.get(this.exchequercode).value
    };

    this.customerService.AddIcsId(this.customers_id, addicsid)
    .subscribe((data) => {
      let response = data['message'];
      console.log(response);
      this.form.reset();
      var modal = document.getElementById('id01');
      modal.style.display = "none";
      this.loadCustomer();
      this.loading = false;
    },
    error => {
        this.error = error;
        this.loading = false;
        var modal = document.getElementById('id01');
        modal.style.display = "block";
    });
  }

  UpdateEmail(){
    this.loading = true;
    if(this.emailForm.invalid)
    return;

    let updateEmail: any = {
      customers_id: Number(this.customers_id),
      email: this.email,
      updatedEmail: this.emailForm.get('email').value
    };

    this.customerService.UpdateEmail(updateEmail)
    .subscribe((data) => {
      let response = data['message'];
      this.$response = response;
      this.form.reset();
      var modal = document.getElementById('id03');
      modal.style.display = "none";
      this.loadCustomer();
      this.loading = false;
    },
    error => {
        this.error = error;
        this.loading = false;
        var modal = document.getElementById('id03');
        modal.style.display = "block";
    });
  }

  loadCustomer() {
    this.customer$ = this.customerService.getCustomer(this.customers_id);
    this.customerService.getCustomer(this.customers_id).subscribe(res => {
      this.dataSource.data = res.order_history as unknown as Customer[];
      this.email = res.customers_email_address;
    });
  }

  back() {
    this.router.navigate(['/customers']);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
