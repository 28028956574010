import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';

@Component({
  selector: 'app-monthlyorders',
  templateUrl: './monthlyorders.component.html',
  styleUrls: ['./monthlyorders.component.css']
})
export class MonthlyordersComponent implements OnInit {

  currentDate: Date;
  currentMonth: Number;
  currentYear: Number;
  constructor(
  private router: Router
  ) 
  {
    this.currentDate = new Date();
    this.currentMonth = 1 + this.currentDate.getMonth();
    this.currentYear = this.currentDate.getFullYear();
  }
  ngOnInit() {
    this.router.navigate(['monthlyorders/' + this.currentMonth + '/' + this.currentYear]);
  }

}
