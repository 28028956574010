import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrderService } from 'app/services/order.service';

@Component({
  selector: 'app-orderstatushistory',
  templateUrl: './orderstatushistory.component.html',
  styleUrls: ['./orderstatushistory.component.css']
})
export class OrderstatushistoryComponent implements OnInit {

  orderStatus: number;
  orders_id: number;

  constructor(
    public dialogRef: MatDialogRef<OrderstatushistoryComponent>,
    private orderService: OrderService, 
    ) 
    {
      this.orderService.apiData$.subscribe(data => {
        this.orders_id = Number(data);
      })
    }

  ngOnInit(): void {
  }

  onChangeEvent(value : number){
    this.orderService.UpdateOrderStatus(this.orders_id,value).subscribe(data => {
      this.orderService.setData(data);
      this.dialogRef.close();
    })
  }
}
