import { Component, OnInit } from '@angular/core';
import { MembershipRenewals, society } from 'app/models/MembershipRenewals';
import { PeopleServerService } from 'app/services/people-server.service';

//datatable
import { ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { textChangeRangeIsUnchanged } from 'typescript';

export class MembershipRenewalsViewModel {
    user_id: number;
    email: string;
    name: string;
    name_f: string;
    name_l: string;
    dattm: Date;
    expire_date: Date;
    invoice_id: number;
    invoice_payment_id: number;
    sin: string;
    amount: number;
    exchequerStockCode: string;
    grade: string;
    society: string;

    constructor(m: MembershipRenewals) {
      this.user_id = m.user_id;
      this.email = m.email;
      this.name = m.name;
      this.name_f = m.name_f;
      this.name_l = m.name_l;
      this.dattm = m.dattm;
      this.expire_date = m.expire_date;
      this.invoice_id = m.invoice_id;
      this.invoice_payment_id = m.invoice_payment_id;
      this.sin = m.sin;
      this.amount = m.amount;
      this.exchequerStockCode = m.exchequerStockCode;
      this.grade = this.extractGrades(m.society);
      this.society = this.extractSocieties(m.society);
    }

    extractGrades(s: society[]):string {
      
      let result: string = "";

      s.forEach(x => {
        result += `${x.grade}<br>`;
      });
      return result;
    }
    extractSocieties(s: society[]):string {
      
      let result: string = "";

      s.forEach(x => {
        result += x.society_title + '<br>';
      });
      return result;
    }
}

@Component({
  selector: 'app-ps-renewals',
  templateUrl: './ps-renewals.component.html',
  styleUrls: ['./ps-renewals.component.scss']
})
export class PsRenewalsComponent implements OnInit {

  loading = false;
  response: MembershipRenewals;
  user_id = 0;
  index = 0;
  table: MembershipRenewals[]=[];//actual backend model - this is the only thing from Ash's code that I use - maciej
  term: any;
  p: number = 1;
  end: number;

  // datatable
  data: MembershipRenewalsViewModel[];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id','name','email','paymentDate','expiryDate','invoice','amount','sin','grade','society','action'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) tablee: MatTable<any>;

  constructor(private psService: PeopleServerService) { }

  ngOnInit(): void {
    this.getRegistrants();
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(val:string){

    console.log("applying filter")

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    } else {
      console.log("no paginator on ", this.dataSource);
    }
    this.dataSource.filter = val.trim().toLowerCase();
  }

  getRegistrants(){
    this.loading = true;
    this.psService.getMembers().subscribe(data => {
      this.table = data;
      this.end = this.table.length;
      this.loading = false;
      console.log("ps-renewals retrieved (psService.getMembers()[0]):", this.table[0]);
      //console.log(this.table[0]);

      // datatable
      this.data = data.map( x => new MembershipRenewalsViewModel(x));
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
    })
  }

  

  push(id:number) {

    this.loading = true;

    let toBePushed: MembershipRenewals = this.table.find(x => x.user_id == id);
    console.log("ps-renewals will push to exchequer:", toBePushed);

    if (! toBePushed) { 
      console.log(`cannot push ${toBePushed}`);
      return;
    }
    


    this.psService.exchequerPostMemberInvoice(JSON.stringify(toBePushed))
    .subscribe(data =>{
      console.log("exchequerPostMemberInvoice returned: ", data);
      
      let indexOfPushed = this.table.findIndex(x => x.user_id == id);

      if(indexOfPushed == -1) {
        console.log(`cannot find element with user_id ==  ${id}`);
      }
      console.log("indexOfPushed: " + indexOfPushed);

      this.table.splice(indexOfPushed,1);
      // remap table to data again to ensure that both arrays match each other?
      this.data.splice(indexOfPushed,1);

      this.dataSource.data = this.data;
      this.tablee.renderRows();

      this.loading = false;
    });


  }

  // // Ashraful's code with added name_f & name_l fields.
  // // left in case something's wrong with mine; not called anymore
  // exchequerPush(event: any){
  //   this.loading = true;
  //   let value = event.target.innerHTML;
  //   this.user_id = value.replace(/<\/?.+?>|Push/ig, '');
  //   this.index = this.table.findIndex(x => x.user_id == this.user_id);
  //   this.response = this.table[this.index];

  //   // \s stands for whitespace chars
  //   let namesArray = this.response.name.split(/(\s)/);

  //   // fix empty array elements, e.g.: ["Linda", " ", "Duggan", " ", ""]
  //   namesArray = namesArray.filter( x => x.trim() != '');

  //   // fix too many array elements. E.g.: ["Elizabeth", "Anne", "Heffernan"]
  //   let name_f = namesArray.slice(0, -1).join(' ');
  //   let name_l = namesArray.slice(-1)[0];

  //   this.response.name_f = name_f;
  //   this.response.name_l = name_l;

  //   console.log("ps-renewals POSTs to exchequer/postExchequerEventAttendeeInvoice:", this.response);
  //   //console.log(this.response);

  //   //return;
    
  //   let json = JSON.stringify(this.response);

  //   this.psService.exchequerPostMemberInvoice(json)
  //   .subscribe(data =>{
  //     console.log("exchequerPostMemberInvoice: ", data);
      
  //     var arr = this.table;
  //     arr.splice(this.index, 1)
  //     this.table = arr;
  //     this.loading = false;
  //   });
  // }
}
