import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalidrequest',
  templateUrl: './invalidrequest.component.html',
  styleUrls: ['./invalidrequest.component.scss']
})
export class InvalidrequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
