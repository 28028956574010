import { Component, OnInit } from '@angular/core';
import { InvoiceById } from 'app/models/Invoice';
import { Observable } from 'rxjs';
import { InvoiceService } from 'app/services/invoice.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  loading = false;
  invoice: InvoiceById;
  invoice_id: number;
  constructor(
    private invoiceService: InvoiceService,
    private avRoute: ActivatedRoute,
    private _location: Location
  ) {
    const idParam = 'id';
    if (this.avRoute.snapshot.params[idParam]) {
      this.invoice_id = this.avRoute.snapshot.params[idParam];
    }
   }

  ngOnInit(): void {
    this.loadInvoice();
  }

  loadInvoice(){
    this.invoiceService.getInvoice(this.invoice_id).subscribe(res => {
      this.invoice = res;
    })
  }

  back() {
    this._location.back();
  }

}
