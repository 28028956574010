import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Product } from 'app/models/product';
import { ProductService } from 'app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UpdateExchequer } from 'app/models/UpdateExchequer';
import { UpdatePrice } from 'app/models/UpdatePrice';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, AfterViewInit {

  public tableColumns = ['customers_firstname', 'customers_lastname', 'company', 'customers_id'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<Product>();

  form: FormGroup;
  priceUpdateForm: FormGroup;
  p2uForm: FormGroup;
  products_price: any;
  exchequerName: string;
  exchequerDepartment: string;
  exchequerGLcode: string;
  loading = false;
  submitted = false;
  product$: Product;
  products_id: number;
  $response: any;
  existingproduct: Product;
  updateExchequer: Product;
  currentTime : Date;

  constructor(
    private productService: ProductService, 
    private avRoute: ActivatedRoute,
    private formBuilder: FormBuilder
    ) 
    {
      this.products_price = 'price';
      this.exchequerName = 'name';
      this.exchequerDepartment = 'department';
      this.exchequerGLcode = 'glcode';
      this.p2uForm = this.formBuilder.group(
        {
          enrolmentTestId: ['', [Validators.required]],
          enrolmentLearnId: ['', [Validators.required]],
          enrolmentTestQty: ['', [Validators.required]],
          enrolmentLearnQty: ['', [Validators.required]],
          enlightCertsQty: ['', [Validators.required]],
          enlightDiagsQty: ['', [Validators.required]],
          enlightAdvCertQty: ['', [Validators.required]],
          enlightAdvDiagQty: ['', [Validators.required]],
          unbreakableQty: ['', [Validators.required]],
          unbreakableId: ['', [Validators.required]]
        }
      );
      this.priceUpdateForm = this.formBuilder.group(
        {
          price: ['', [Validators.required]]
        }
      );
      this.form = this.formBuilder.group(
        {
          name: ['', [Validators.required]],
          department: ['', [Validators.required]],
          glcode: ['', [Validators.required]]
        }
      );
      const idParam = 'id';
      if (this.avRoute.snapshot.params[idParam]) {
        this.products_id = this.avRoute.snapshot.params[idParam];
      }
    }

  ngOnInit(): void {
    this.loadProductT();
    this.loadProduct();
    this.resetForms();
    this.getCurrentTime();
  }

  getCurrentTime(){
    this.currentTime = new Date();
  }

  resetForms(){
    this.productService.getAssociatedCustomers(this.products_id)
    .subscribe(data => (
      this.existingproduct = data,
      this.priceUpdateForm.controls[this.products_price].setValue('€' + data.products_price),
      this.form.controls[this.exchequerName].setValue(data.exchequerName),
      this.form.controls[this.exchequerDepartment].setValue(data.exchequerDepartment),
      this.form.controls[this.exchequerGLcode].setValue(data.exchequerGLcode),
      this.p2uForm.controls['enrolmentTestId'].setValue(data.enrolmentTestId),
      this.p2uForm.controls['enrolmentLearnId'].setValue(data.enrolmentLearnId),
      this.p2uForm.controls['enrolmentTestQty'].setValue(data.enrolmentTestQty),
      this.p2uForm.controls['enrolmentLearnQty'].setValue(data.enrolmentLearnQty),
      this.p2uForm.controls['enlightCertsQty'].setValue(data.enlightCertsQty),
      this.p2uForm.controls['enlightDiagsQty'].setValue(data.enlightDiagsQty),
      this.p2uForm.controls['enlightAdvCertQty'].setValue(data.enlightAdvCertQty),
      this.p2uForm.controls['enlightAdvDiagQty'].setValue(data.enlightAdvDiagQty),
      this.p2uForm.controls['unbreakableQty'].setValue(data.unbreakableQty),
      this.p2uForm.controls['unbreakableId'].setValue(data.unbreakableId)
    ));
  }

  public loadProductT = () => {
    this.loading = true;
    this.productService.getAssociatedCustomers(this.products_id).subscribe(res => {
      this.dataSource.data = res.customer as unknown as Product[];
      this.loading = false;
    });
  }

  save(){
    this.submitted = true;
    if(this.form.invalid) {
      return;
    }

    this.loading = true;
    let upExchequer: UpdateExchequer = {
      products_id: this.existingproduct.products_id,
      exchequerDepartment: this.form.get(this.exchequerDepartment).value,
      exchequerName: this.form.get(this.exchequerName).value,
      exchequerGLcode: this.form.get(this.exchequerGLcode).value
    };
    this.productService.updateExhchequer(upExchequer)
    .subscribe((data) => {
      let response = 'Exchequer has been updated!';
      this.$response = response;
      this.loadProduct();
      this.form.reset();
      this.resetForms();
    })
  }

  UpdateP2U(){
    this.submitted = true;
    if(this.p2uForm.invalid) {
      return;
    }

    this.loading = true;
    
    let upP2U = {
      products_id: this.existingproduct.products_id,
      enrolmentTestId : this.p2uForm.get('enrolmentTestId').value,
      enrolmentLearnId : this.p2uForm.get('enrolmentLearnId').value,
      enrolmentTestQty : this.p2uForm.get('enrolmentTestQty').value,
      enrolmentLearnQty : this.p2uForm.get('enrolmentLearnQty').value,
      enlightCertsQty : this.p2uForm.get('enlightCertsQty').value,
      enlightDiagsQty : this.p2uForm.get('enlightDiagsQty').value,
      enlightAdvCertQty : this.p2uForm.get('enlightAdvCertQty').value,
      enlightAdvDiagQty : this.p2uForm.get('enlightAdvDiagQty').value,
      unbreakableQty : this.p2uForm.get('unbreakableQty').value,
      unbreakableId : this.p2uForm.get('unbreakableId').value,
    };
    console.log(upP2U);
    this.productService.updateProductsActions(upP2U)
    .subscribe((data) => {
      let response = data['message'];
      this.$response = response;
      this.loadProduct();
      this.p2uForm.reset();
      this.resetForms();
    })
  }

  updatePrice(){
    this.submitted = true;
    if(this.priceUpdateForm.invalid) {
      return;
    }

    this.loading = true;
    let updatePrice: UpdatePrice = {
      products_id: this.existingproduct.products_id,
      products_price: this.priceUpdateForm.get(this.products_price).value,
    };
    this.productService.updateProductPrice(updatePrice)
    .subscribe((data) => {
      let response = data['message'];
      this.$response = response;
      this.loadProduct();
      this.priceUpdateForm.reset();
      this.resetForms();
    })
  }
  
  get price() {return this.priceUpdateForm.get(this.products_price)};
  get name() { return this.form.get(this.exchequerName)};
  get department() { return this.form.get(this.exchequerDepartment)};
  get glcode() { return this.form.get(this.exchequerGLcode)};

  loadProduct = () => {
    this.loading = true;
    this.productService.getAssociatedCustomers(this.products_id)
    .subscribe(res => {
      let response = res;
      this.product$ = response;
      this.loading = false;
    });
  };

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  };

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
}
