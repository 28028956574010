import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Customer } from 'app/models/customer';
import { CustomerService } from 'app/services/customer.service';
import {Location} from '@angular/common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-superuser',
  templateUrl: './superuser.component.html',
  styleUrls: ['./superuser.component.css']
})
export class SuperuserComponent implements OnInit {

    token$: String;
    customer$: Observable<Customer>;
    customers_id: number;

    formTitle: string;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    existingCustomer: Customer;

    constructor(
        private _location: Location,
        private customerService: CustomerService,
        private formBuilder: FormBuilder,
        private avRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        this.formTitle = 'username';
        const idParam = 'id';
        if (this.avRoute.snapshot.params[idParam]) {
        this.customers_id = this.avRoute.snapshot.params[idParam];
        };
    }

    ngOnInit() {
        this.loadCustomer();
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required]
        });
        this.customerService.getCustomer(this.customers_id)
        .subscribe(data => (
        this.existingCustomer = data,
        this.loginForm.controls[this.formTitle].setValue(data.customers_email_address),
        this.authenticationService.SuperUserlogin(this.existingCustomer.customers_email_address)
        .pipe(first())
        .subscribe(
            data => 
            {
                let token = data['access_token'];
                this.token$ = token;
                window.open(environment.shopUrl + "/#/superuser?" + this.token$);
                console.log(token);
            },
            error => 
            {
                this.error = error;
                this.loading = false;
            })
        ));
        this.router.navigate(['/customers/', this.customers_id]);
    }

    loadCustomer() {
        this.customer$ = this.customerService.getCustomer(this.customers_id);
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    login()
    {
        window.open("https://shop.ics.ie/superuser?" + this.token$);
    }
    cancel() {
        this._location.back();
    }
    get username() { return this.loginForm.get(this.formTitle); }
}
