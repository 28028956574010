import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { OrderService } from 'app/services/order.service';
import { ExchequerResponse } from 'app/models/ExchequerResponse';
import * as jspdf from 'jspdf';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.css']
})
export class ResponseComponent implements OnInit {

  @ViewChild('content', {static: false}) content: ElementRef; 

  data: ExchequerResponse[];
  date: Date;

  constructor(private orderService: OrderService) {
    this.orderService.apiData$.subscribe(data => this.data = data)
  }

  ngOnInit(): void {
    let date: Date = new Date();
    this.date = date;  
  }
  
  exportAsPDF()
  {
    const doc = new jspdf();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const content = this.content.nativeElement;
    doc.fromHTML(content.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('ExchequerPreview.pdf');
  }
}
