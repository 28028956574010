import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { OrderService } from 'app/services/order.service';
import Chart = require('chart.js');
import { Console } from 'console';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  loading = false;
  error = '';
  yearlyTotal: number[] = [];

  years: number[];
  // Just add more color defninitions here if needed
  chartColors: string[] = ["#5491DA", "#E74C3C", "#82E0AA"];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  Linechart: Chart;
  

  constructor(private shopOrderService: OrderService) { }

  ngOnInit() {

    this.getOrdersData(2);
    
  }

  // oryginal Ash's code
  // getOrdersDataa(){

  //   this.shopOrderService.getYearlyOrders().subscribe(
      
  //     shopOrders => {

  //       console.log("dashboard data:", shopOrders);

  //       this.shopOrders = shopOrders;
  //       this.loading = false;

  //       const runningTotal2020 = this.shopOrders[0].reduce((accumulator, order) => {
  //         return + order + accumulator;
  //       }, 0);
  //       this.yearlyTotal = Math.round(runningTotal2020).toLocaleString();

  //       this.Linechart = new Chart('canvas', {
  //         type: 'bar',
  //         data: {
  //           labels: ['Apr', `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`, 'Jan', 'Feb', 'Mar'],
  //           datasets: [
  //             {
  //               data: [41814, 70080, 22143, 20502, 242156, 538587, 136546, 92495, 22048, 79689, 56822, 37570],
  //               backgroundColor: '#5491DA',
  //               label: "2018"
  //             },
  //             {
  //               data: [40167, 47586, 23081, 9333, 244088, 473137, 129830, 45971, 40739, 60565, 61772, 31237],
  //               backgroundColor: '#E74C3C',
  //               label: "2019"
  //             },
  //             {
  //               data: this.shopOrders[0],
  //               backgroundColor: '#82E0AA',
  //               label: "2020"
  //             }
  //           ]
  //         },
  //         options: {
  //           tooltips: {
  //             mode: 'index',
  //             intersect: false
  //           },
  //           scales: {
  //             yAxes: [{
  //               ticks: {
  //                 callback: function (value, index, values) {
  //                   return '€' + value.toLocaleString("en-IE", { currency: "EUR" });
  //                 }
  //               }
  //             }]
  //           }
  //         }
  //       });
  //     },

  //     error => {
  //       this.error = error;
  //     });
  // }

  getOrdersData(yearsBack : number){

    let date = new Date();
    let currentMonth: number = date.getMonth() + 1; //js getMonth() counts from january = 0 to december = 11
    let currentCalendarYear: number = date.getFullYear();

    // update 01/04/2021 - financial year now matches the calendar year
    // // financial year starts 1st April
    let currentFinancialYear : number;
    // if(currentMonth < 4) {
    //   currentFinancialYear = currentCalendarYear -1;
    // } else {
    //   currentFinancialYear = currentCalendarYear;
    // }
    currentFinancialYear = currentCalendarYear;

    let years : number[] = [];

    for (let i = currentFinancialYear - yearsBack ; i <= currentFinancialYear; i++) {
      years.push(i);
    }

    // for use by yearly orders "widget" in the template 
    this.years = years;

    this.loading = true;
    this.shopOrderService.getYearlyOrders(years).subscribe(
      
      shopOrders => {

        console.log("shopOrderService returned:", shopOrders);

        for(let i = 0; i < shopOrders.length; i++) {

          this.yearlyTotal[i] = 
            Math.round(
              shopOrders[i].reduce((accumulator, order) => { return + order + accumulator; }, 0)
            );
        }

        this.loading = false;

        this.Linechart = new Chart('canvas', {
          type: 'bar',
          data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`],
            datasets: this.getDatasets(shopOrders, years)
          },
          options: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            scales: {
              yAxes: [{
                ticks: {
                  callback: function (value, index, values) {
                    return '€' + value.toLocaleString("en-IE", { currency: "EUR" });
                  }
                }
              }]
            }
          }
        });
      },

      error => {
        this.error = error;
      });
  }

  getDatasets(data : number[][], years: number[]): any[] {
    let datasets: any[] = [];

    for (let i = 0; i < data.length; i++) {
      datasets.push({
        data: data[i],
        // modulo length to make sure we never run out of chart colors
        backgroundColor: this.chartColors[i % this.chartColors.length],
        label: `${years[i]}`
      });
    }

    return datasets;
  }
}
