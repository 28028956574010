import { Vendors } from '../models/Vendors';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Invoice, InvoiceList, InvoiceById } from 'app/models/Invoice';
import { ExchequerResponse } from 'app/models/ExchequerResponse';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/vendors/';
  }

  getStockCodes(): Observable<any> {
    return this.http.get<any>(`${environment.apiCmsExchequer}stockcodes`);
  }

  getVendors(): Observable<Vendors[]> {
    return this.http.get<Vendors[]>(this.myAppUrl + this.myApiUrl).pipe();
  }

  getInvoices(): Observable<InvoiceList[]> {
    return this.http.get<InvoiceList[]>(this.myAppUrl + this.myApiUrl + 'invoice').pipe();
  }

  getInvoice(invoice_id: number): Observable<InvoiceById> {
    return this.http.get<InvoiceById>(this.myAppUrl + this.myApiUrl + 'invoice/' + invoice_id).pipe(
      map(res => {
        return res;
      })
    );
  }

  AddVendor (vendor) {
    return this.http.post(this.myAppUrl + this.myApiUrl, JSON.stringify(vendor), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  AddInvoice (invoice) {
    console.log("POSTing this invoice to api/vendors/invoice", invoice);
    return this.http.post(this.myAppUrl + this.myApiUrl + 'invoice', JSON.stringify(invoice), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }
  exchequerPushFromInvoices(exchequerPush){
    // return this.http.post('https://cms.ics-skills.net/api/exchequer/processExchequerPreview', JSON.stringify(exchequerPush), this.httpOptions).pipe(
    console.log("exchequerPushFromInvoices POSTing", exchequerPush); //return null;
    return this.http.post('https://cms.ics-skills.net/api/exchequer/postExchequerThirdPartyInvoice', JSON.stringify(exchequerPush), this.httpOptions).pipe(
      map(data => {
        return data as ExchequerResponse[];
      })
    );
  }

  exchequerPush(exchequerPush){
    return this.http.post('https://cms.ics-skills.net/api/exchequer/processExchequerPreview', JSON.stringify(exchequerPush), this.httpOptions).pipe(
      map(data => {
        return data as ExchequerResponse[];
      })
    );
  }
}
