import { AuthenticationService } from './../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-login', 
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css'] 
})
export class LoginComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    response$: any;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });

        this.forgotPasswordForm = this.formBuilder.group({
            username: ['', Validators.required],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get fp() { return this.forgotPasswordForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    ForgotPassword() {
        this.submitted = true;
        this.loading = true;
        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            this.loading = false;
            return;
        }
        this.authenticationService.forgotpassword(this.fp.username.value)
            .pipe(first())
            .subscribe(
            data => {
            let response = 'If your account exists you will soon receive a password reset email; Keep an eye on your junk folder too!';
            this.response$ = response;
            console.log(response);
            this.loading = false;
            setTimeout(() => {
            window.location.reload();
            }, 3500);  //3s
            this.forgotPasswordForm.controls['username'].disable();
        });
    }
}
