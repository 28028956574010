import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EventRegistration } from 'app/models/EventRegistration';
import { OrderService } from 'app/services/order.service';
import { PeopleServerService } from 'app/services/people-server.service';
import { AlertService } from '../_alert';
import { saveAs } from 'file-saver'

export enum PaymentType {
  stripe,
  offline
}

export enum ActiveFilter {
  sinOnly,
  notInvoiced,
  stripe,
  none
}

@Component({
  selector: 'app-ps-events-registrants',
  templateUrl: './ps-events-registrants.component.html',
  styleUrls: ['./ps-events-registrants.component.css']
})
export class PsEventsRegistrantsComponent implements OnInit, AfterViewInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  loading = false;
  // Color1 = false;
  // Color2 = false;
  // Color3 = false;
  // id : number;
  // event: EventRegistration;
  selectedMonth: number;
  selectedYear: number;
  // stripePayment: boolean = true;
  // offlineTransactions: boolean = false;


  
  // provide access to PaymentType from template
  public get PaymentType() {
    return PaymentType;
  }
  paymentType: PaymentType = PaymentType.stripe;

  // provide access from template
  public get ActiveFilter() {
    return ActiveFilter;
  }
  activeFilter = ActiveFilter.none;

  currentYear = new Date().getFullYear();

  
  public tableColumns = ['registrant_id','register_date','first_name','organization','event_id','event_title','event_date','attended','payment_method','total_amount','discount_amount','amount', 'sin','exchequerStockCode','expush'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<EventRegistration>();

  constructor(
    protected alertService: AlertService, 
    private psService: PeopleServerService, 
    private orderService: OrderService) { }

  ngOnInit(): void {
    // this.getProcessPostedOrdersAndRegistrants(); //TODO!
    this.getRegistrants();
    this.triggerProcessingOfPostedOrders();
  }

  ExportCSV() {
    // if(this.stripePayment == true)
    if(this.paymentType === PaymentType.stripe)
    {
      this.orderService.Export_Stripe_Payments_CSV(Number(this.selectedMonth),Number(this.selectedYear)).subscribe((data)=> {
        var blob = new Blob([data], {type: 'text/csv'});
        saveAs(blob, "Stripe_Payments_" + this.selectedMonth + '_' + this.selectedYear + ".csv");
        this.selectedMonth = null;
        this.selectedYear = null;
      })
    }
    // else if(this.offlineTransactions == true)
    if(this.paymentType === PaymentType.offline)
    {
      this.orderService.Export_Offline_Transactions_CSV(Number(this.selectedMonth),Number(this.selectedYear)).subscribe((data)=> {
        var blob = new Blob([data], {type: 'text/csv'});
        saveAs(blob, "Offline_Transactions_" + this.selectedMonth + '_' + this.selectedYear + ".csv");
        this.selectedMonth = null;
        this.selectedYear = null;
      })
    }
  }

  // getProcessPostedOrdersAndRegistrants(){
  //   this.loading = true;
  //   this.orderService.processPostedOrders().subscribe(res => {
  //     console.log(res);
  //     this.getRegistrants();
  //   });
  // }
  triggerProcessingOfPostedOrders(){
    this.loading = true;
    this.orderService.processPostedOrders().subscribe(res => {
      console.log(res);
    });
  }

  getRegistrants(){
    this.psService.getRegistrants().subscribe(data => {
      this.activeFilter = ActiveFilter.none;
      console.log("got registrants:", data)
      this.dataSource.data = data
        .filter(order => order.payment_method != 'os_free')
        .filter(x => x.payment_method.split('-').shift() != 'ATTACH')
        .filter(x => x.exchequerStockCode != 'EXCHEQUER-EXCLUDE') as EventRegistration[];
      // this.event = EventRegistration[0];
      this.loading = false;

      // //test for duplicates - maciej
      // let dups : EventRegistration[] = [];
      // let aSet = new Set();
      // data.forEach( x => {
      //   if(aSet.size === aSet.add(x.registrant_id).size) {//add(x.first_name + x.last_name).size) {
      //     console.log("Found duplicate:", x);
      //     dups.push(x);
      //   }
      // });
      // console.log("unique: " + aSet.size + ", duplicates:", dups.length);

    });
  }

  // exchequerPushSingle(event:any){
  //   let value = event.target.innerHTML;
  //   this.id = value.replace(/<\/?.+?>|Push/ig, '');
  //   console.log('registrant_id: '+ this.id);
  //   this.psService.exchequerPush(this.dataSource.data.filter(x => x.registrant_id == this.id)[0]).subscribe((data) => {
  //     console.log('Response from API: ' +'\n' +'\n' + JSON.stringify(data));
  //     this.dataSource.data = this.dataSource.data.filter(order => order.payment_method != 'os_free').filter(order => order.registrant_id != this.id);
  //     this.alertService.success('Registrant ID: ' + data.cart_order_id + ' Status: ' + data.status,this.options);
  //   });
  // }
  exchequerPushSingle(registrantId : number){

    // let value = event.target.innerHTML;
    // let registrantId = value.replace(/<\/?.+?>|Push/ig, '');
    // console.log('registrant_id: '+ registrantId + ", registrantRowId: ", registrantRowId);

    let registrant: EventRegistration = this.dataSource.data.find(x => x.registrant_id == registrantId);

    console.log("registrant:", registrant);

    this.psService.exchequerPush(registrant).subscribe((data) => {
      console.log('Response from psService.exchequerPush:',data);
      this.dataSource.data = this.dataSource.data
        // .filter(order => order.payment_method != 'os_free')// ke?
        .filter(order => order.registrant_id != registrantId);
      this.alertService.success('Registrant ID: ' + data.cart_order_id + ' Status: ' + data.status,this.options);
    });
  }

  filterSinOnly() {
    this.activeFilter = ActiveFilter.sinOnly;
    this.psService.getRegistrants().subscribe(data => {
      this.dataSource.data = data.filter(order => order.sin != 'SIN000000').filter(order => order.sin != 'na').filter(order => order.sin != '');
    })
  }

  filterStripe(){
    this.activeFilter = ActiveFilter.stripe;
    this.psService.getRegistrants().subscribe(data => {
      this.dataSource.data = data.filter(order => order.payment_method == 'os_stripe');
    })
  }

  filterNotInvoiced() {
    this.activeFilter = ActiveFilter.notInvoiced;
    this.psService.getRegistrants().subscribe(data => {
      this.dataSource.data = data.filter(order => order.sin == '').filter(order => order.payment_method != 'os_free');
    })
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

}
