import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopOrder } from 'app/models/Shop-order';
import { OrderService } from 'app/services/order.service';
import { AlertService } from '../_alert';

@Component({
  selector: 'app-amf',
  templateUrl: './amf.component.html',
  styleUrls: ['./amf.component.scss']
})
export class AmfComponent implements OnInit, AfterViewInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  loading = false;
  shopOrders: ShopOrder[];
  shopTotal: number;
  currentMonth: number;
  currentYear: number;
  monthName: string;
  currentDate: Date;
  centre_id: number;
  active: boolean = false;

  public dataSource = new MatTableDataSource<ShopOrder>();
  public tableColumns = [
    'orderedDate','organisation','centre_id', 'centreStatus', 'sin_Number','renew'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  Status: string;
  
  constructor(
    protected alertService: AlertService,
    private shopOrderService: OrderService,
    private route: ActivatedRoute,
    private router: Router) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit() {
    const months = [`January`, `February`, `March`,
    `April`, `May`, `June`, `July`, `August`,
    `September`, `October`, `November`,
    `December`];
    
    const month = +this.route.snapshot.paramMap.get('month');
    const year = +this.route.snapshot.paramMap.get('year');
    this.currentYear = year;
    this.currentMonth = month;
    this.monthName = months[month - 1];
    this.loading = true;
    this.shopOrderService.getCurrentAMFRenewals().subscribe((data => {
      this.shopTotal = data['total_renewals'];
    }))
    this.shopOrderService.getMonthlyAMFOrders(month, year).subscribe(shopOrders => {
      this.shopOrders = shopOrders;
      this.dataSource.data = shopOrders;
      this.loading = false;
      if(this.dataSource.data.length > 1)
      {
        this.active = true;
      }
    });
  }

  getValue($event:any) {
    this.centre_id = 0;
    let value = $event.target.innerHTML;
    this.centre_id = value.replace(/<\/?.+?>/ig, '');
    console.log(this.centre_id);
  }

  private delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async exchequerPush(){
    this.loading = true;
    for(const item in this.dataSource.data)
    {
      await this.delay(2000)
      this.shopOrderService.renewAMF(this.dataSource.data[item].centre_id).subscribe((data => {
        console.log(data);
        this.shopTotal = this.shopTotal + 1;
        this.alertService.success(data['message'].toString(),this.options);
      }))
    }
    this.dataSource.data = [];
    this.loading = false;
  }

  renew(){
    this.loading = true;
    this.shopOrderService.renewAMF(this.centre_id).subscribe((data => {
      console.log(data);
      this.shopTotal = this.shopTotal + 1;
      this.dataSource.data = this.dataSource.data.filter(x => x.centre_id != this.centre_id);
      this.alertService.success(data['message'].toString(),this.options);
      this.loading = false;
    }))
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
