import { Customer } from '../models/customer';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {

  myAppUrl: string;
  myApiUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/customer/';
  }

  getCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(this.myAppUrl + this.myApiUrl + 'list').pipe();
  }

  getCustomer(customers_id: number): Observable<Customer> {
    return this.http.get<Customer>(this.myAppUrl + this.myApiUrl + customers_id).pipe();
  }

  UpdatePaymentMethod(customers_id: number, value: boolean){
    return this.http.get(this.myAppUrl + this.myApiUrl + 'purchaseonaccount/' + customers_id + '/' + value).pipe();
  }

  AddCustomer (customer) {
    return this.http.post(this.myAppUrl + 'users/register', JSON.stringify(customer), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  UpdateProfile (customers_id: number, customer) {
    return this.http.post(this.myAppUrl + 'users/adminupdate/' + customers_id, JSON.stringify(customer), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  AddIcsId (customers_id: number, icsid) {
    return this.http.post(this.myAppUrl + 'users/assign/' + customers_id, JSON.stringify(icsid), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  UpdateEmail (email) {
    return this.http.post(this.myAppUrl + 'users/emailupdate', JSON.stringify(email), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }
}
