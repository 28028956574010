import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Sales_of_stock } from 'app/models/Sales_of_stock';
import { OrderService } from 'app/services/order.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-sales-of-stock',
  templateUrl: './sales-of-stock.component.html',
  styleUrls: ['./sales-of-stock.component.css']
})
export class SalesOfStockComponent implements OnInit, AfterViewInit {

  loading = false;
  shopOrders: Sales_of_stock[];
  filteredShopOrders: Sales_of_stock[];
  shopTotal: string;
  currentMonth: number;
  currentYear: number;
  monthName: string;
  Color1 = false;
  Color2 = false;
  Color3 = false;

  public dataSource = new MatTableDataSource<Sales_of_stock>();
  public tableColumns = [
    'orders_products_id','date_purchased','Year','Month',
    'products_name','exchequerName','products_quantity',
    'products_price','final_price','orders_id'];
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  yearlyStockSales: Sales_of_stock[];
  monthlyStockSales: Sales_of_stock[];
  
  constructor(
    private shopOrderService: OrderService,
    private route: ActivatedRoute,
    private router: Router) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
  
  ngOnInit() {

    this.loading = true;
    // const centreId = +this.route.snapshot.paramMap.get('id');
    const months = [`January`, `February`, `March`,
    `April`, `May`, `June`, `July`, `August`,
    `September`, `October`, `November`,
    `December`];

    const month = +this.route.snapshot.paramMap.get('month');
    const year = +this.route.snapshot.paramMap.get('year');
    this.currentYear = year;
    this.currentMonth = month;

    if(month == 0)
    {
      this.getYearlySales();
    }
    else
    {
      this.monthName = months[month - 1];
      this.shopOrderService.getSales_of_stock(month, year).subscribe(shopOrders => {
      this.monthlyStockSales = shopOrders
      this.shopOrders = shopOrders;
      this.dataSource.data = shopOrders;

      const runningTotal = this.shopOrders.reduce((accumulator, order) => {
        return order.products_price * order.products_quantity + accumulator;
      }, 0);

      this.shopTotal = Math.round(runningTotal).toLocaleString();
      this.loading = false;
    });
    }

    
  }

  getYearlySales()
  {
    const data = this;
    this.loading = true;
    const year = +this.route.snapshot.paramMap.get('year');
    this.shopOrderService.getYearlySales_of_stock(year).subscribe(shopOrders=> {
      data.dataSource.data = shopOrders;
      data.yearlyStockSales = shopOrders;
      this.loading = false;
    });
  }

  ExportCSV() 
  {
    const data = this;
    const month = +this.route.snapshot.paramMap.get('month');
    const year = +this.route.snapshot.paramMap.get('year');
    if(month == 0)
    {
      this.shopOrderService.Export_YearlySales_of_stock_CSV(this.currentYear).subscribe((data)=> {
        var blob = new Blob([data], {type: 'text/csv'});
        console.log(blob);
        saveAs(blob, "Stock_sales_" + this.currentYear + ".csv");
      })
    }
    else
    {
      this.shopOrderService.Export_Sales_of_stock_CSV(this.currentMonth, this.currentYear).subscribe((data)=> {
        var blob = new Blob([data], {type: 'text/csv'});
        console.log(blob);
        saveAs(blob, "Stock_sales_" + this.currentMonth + '_' + this.currentYear + ".csv");
      })
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
}
