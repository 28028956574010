import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceList, InvoiceById, Invoice } from 'app/models/Invoice';
import { InvoiceService } from 'app/services/invoice.service';
import { OrderService } from 'app/services/order.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

  loading = false;
  invoice_id: number;
  invoiceList: InvoiceById;
  excheqAcc: string;
  hasExchequer = false;
  response: any;
  index = 0;

  public tableColumns = ['id', 'vendor_id', 'total_amount', 'vendors_name', 'date_raised', 'purchaseOrderNumber', 'sin', 'expush'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public dataSource = new MatTableDataSource<InvoiceList>();

  constructor(private orderService: OrderService, private invoiceService: InvoiceService) { }

  ngOnInit(): void {
    this.orderService.processPostedOrders().subscribe(data => {
      console.log(data);
    });
    this.loadInvoices();
    this.loading = true;
  }

  loadInvoices(){
    this.invoiceService.getInvoices().subscribe(res => {
      this.dataSource.data = res as InvoiceList[];
      this.loading = false;
      console.log("loadInvoices got:",res);
    })
  }

  exchequerPushSingle(event:any){
    this.hasExchequer = false;
    let value = event.target.innerHTML;
    this.invoice_id = value.replace(/<\/?.+?>|Push/ig, '');

    this.index = this.dataSource.data.findIndex(x => x.id == this.invoice_id);
    var arr = this.dataSource.data;
    arr.splice(this.index, 1)
    this.dataSource.data = arr;

    this.invoiceService.getInvoice(this.invoice_id).subscribe(res => {
      this.invoiceList = res;
      console.log("exchequerPushSingle() -> getInvoice got:", res);

      if(res.exchequerRef!= '')
      {
        this.hasExchequer = true;
      }
      let array = [];
      for(const item in res.invoice_Details)
      {
        let invDetails : any = {
          product_id: res.invoice_Details[item].id,
          description: res.invoice_Details[item].description,
          unitPrice: res.invoice_Details[item].unit_price,
          quantity: res.invoice_Details[item].quantity,
          stockCode: res.invoice_Details[item].exchequerStockCode,
          value: res.invoice_Details[item].quantity * res.invoice_Details[0].unit_price
        };
        array.push(invDetails);
      }
      let invoiceOBject: any = {
        // ExchequerAccount : 'ICSS01',
        // ExchequerAccount : res.exchequerCompanyAccount,
        ExchequerAccount : res.exchequerRef,
        exchequerName: res.exchequerRef,//->exchequerAcCode
        cart_order_id : res.id, 
        // altcode: res.vendors_id,
        altcode: res.exchequerAltRef,
        order_date: res.date_raised,
        trans: 'SIN',
        orderSource: 'INVOICE',
        po: res.purchaseOrderNumber,
        orderItems: array     
      };
      // this.invoiceService.exchequerPush(invoiceOBject)
      this.invoiceService.exchequerPushFromInvoices(invoiceOBject)
      .subscribe((data) => {
        this.response = data;
        console.log("exchequerPushSingle() -> exchequerPushFromInvoices returned with:");
        console.log(data);
        this.loading = false;
      });
    })
  }

  // exchequerPush(){
  //   this.loading = true;
  //   for(const item in this.dataSource.data)
  //   {
  //     this.invoiceService.getInvoice(this.dataSource.data[item].id).subscribe(res => {
  //       let array = [];
  //       for(const item in res.invoice_Details)
  //       {
  //         let invDetails : any = {
  //           product_id: res.invoice_Details[item].id,
  //           description: res.invoice_Details[item].description,
  //           unitPrice: res.invoice_Details[item].unit_price,
  //           quantity: res.invoice_Details[item].quantity,
  //           stockCode: res.invoice_Details[item].exchequerStockCode,
  //           value: res.invoice_Details[item].quantity * res.invoice_Details[0].unit_price
  //         };
  //         array.push(invDetails);
  //       }
  //       let invoiceOBject: any = {
  //         // ExchequerAccount : 'ICSS01',exchequerCompanyAccount
  //         ExchequerAccount : res.exchequerCompanyAccount,
  //         exchequerName: res.exchequerRef,
  //         cart_order_id : res.id, 
  //         altcode: res.vendors_id,
  //         order_date: res.date_raised,
  //         trans: 'SIN',
  //         orderSource: 'INVOICE',
  //         po: res.purchaseOrderNumber,
  //         orderItems: array     
  //       };
  //       // this.invoiceService.exchequerPush(invoiceOBject)
  //       this.invoiceService.exchequerPushFromInvoices(invoiceOBject)
  //       .subscribe((data) => {
  //         this.response = data;
  //         console.log("exchequerPushFromInvoices returned with:");
  //         console.log(data);
  //         this.loading = false;
  //       });
  //       this.dataSource.data = this.dataSource.data.splice(0, this.dataSource.data.length - 1);
  //     })
  //   }
  // }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

}
