import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { first } from 'rxjs/operators';
import { PasswordReset } from 'app/models/PasswordReset';
import { CustomValidators } from 'app/helpers/customValidators';
import { ConfirmedValidator } from 'app/helpers/custom-validator';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  $email: string;
  $token: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  returnUrl: string;
  $response: any;
  timeLeft: number;
  interval;
  $seconds: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) {
        this.router.navigate(['/']);
      }
    }

  ngOnInit(): void 
  {
    this.loginForm = this.formBuilder.group
    (
      {
        password: ['', Validators.compose([
          Validators.required,
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(6)
        ])
      ],
        confirmpassword: ['', Validators.minLength(6)],
      }, 
      { 
        validator: ConfirmedValidator('password', 'confirmpassword')
      }
    );

    this.getLink();
    
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() 
  {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    let reset: PasswordReset = {
      username: this.$email,
      password: this.f.password.value,
      confirmpassword: this.f.confirmpassword.value,
      token: this.$token
    };
    this.authenticationService.passwordreset(reset)
    .pipe(first())
    .subscribe(
        data => {
          let response = data['message'];
          this.$response = response + ' ' + 'Redirecting you to the login page in... ';
          let seconds = ' seconds';
          this.$seconds = seconds;
          console.log(response);
          this.loading = false;
          setTimeout(() => {
          this.router.navigate([this.returnUrl]);
          }, 6000);  //5s
          this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
            } else {
              this.timeLeft = 5;
            }
          },1000)
        },
        error => {
            this.error = error;
            this.loading = false;
        });
  }

  getLink(){
    let url = location.search;
    console.log(url.slice(1, -1).split(":",2).pop());
    console.log(url.slice(1, -1));
    let email = url.slice(1, -1).split(":",2).pop();
    this.$email = email;
    let token = url.slice(1, -1);
    this.$token = token;
  }
}
