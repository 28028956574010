import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/authentication.service';
import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import {Location} from '@angular/common';
import { SharedService } from 'app/services/shared.service';
import { Product } from 'app/models/product';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderService } from 'app/services/order.service';
import { CustomerService } from 'app/services/customer.service';

@Component({
    // moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit, OnDestroy{

    form: FormGroup;
    private subscription: Subscription;
    private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;
    cartItemCount: number = 0;
    productAddedTocart: Product[];
    ordering_customer: number;
    customers_id: number;
    orders_id: string;
    found: number = 0;
    submitted = false;

    constructor(
        private orderService: OrderService,
        private customerService: CustomerService,
        private fb: FormBuilder, 
        location: Location, 
        public sharedService:SharedService,  
        private element: ElementRef, 
        private authService: AuthenticationService, 
        private router: Router
        ) 
        {
            this.location = location;
            this.sidebarVisible = false;
            this.orders_id = 'orders_id';
            this.form = this.fb.group({
                orders_id: ['', [Validators.required]]
        });
    }

    ngOnInit(){
        this.subscription = this.sharedService.currentMessage.subscribe((data) => {
            this.cartItemCount = data;
        });
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    search(){
        this.submitted = true;
        if(this.form.invalid)
        {
            alert('Please Enter a valid Order Id(i.e 1225) or Centre Id(i.e CID2635)')
            return;
        }
        let orderid = this.form.get(this.orders_id).value;
        let str = String(orderid);
        if(str.match(/cid/g) != null || str.match(/CID/g) != null)
        {
            if(str === 'cid0' || str === 'CID0')
            {
                alert('Unable to search centre with Id 0!')
                return;
            }
            this.router.navigate(['/search/']);
            this.customerService.getCustomers().subscribe((data) => {
                if(data.filter(x => x.customers_ics_id === str.toUpperCase()).length > 0)
                {
                    let customers_id = data.find(x => x.customers_ics_id === str.toUpperCase()).customers_id;
                    this.router.navigate(['/customers/', customers_id]);
                }
                else
                {
                    alert('Centre not found! Click Ok to see all the centres.');
                    this.router.navigate(['/customers/']);
                }
            })
        }
        else if(str.match(/SIN/g) != null || str.match(/sin/g) != null)
        {
            if(str === 'cin0' || str === 'SIN0' || str === 'SIN')
            {
                alert('Invalid SIN number!')
                return;
            }
            this.router.navigate(['/search/']);
            this.orderService.getOrders().subscribe((data) => {
                if(data.filter(x => x.sin === str.toUpperCase()).length > 0)
                {
                    let orders_id = data.find(x => x.sin === str.toUpperCase()).orders_id;
                    this.router.navigate(['/orders/', orders_id]);
                }
                else{
                    alert('Order not found! Click Ok to see all the orders.');
                    this.router.navigate(['/monthlyorders/']);
                }
            })
        }
        else
        {
            this.router.navigate(['/search/']);
            this.orderService.getOrders().subscribe((data) => {
                if(data.filter(x => x.orders_id === Number(orderid)).length > 0)
                {
                    this.router.navigate(['/orders/', orderid]);
                }
                else{
                    alert('Order not found! Click Ok to see all the orders.');
                    this.router.navigate(['/monthlyorders/']);
                }
            })
        }
    }

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    checkout(){
        if(localStorage.key(1) != null)
        {
          this.ordering_customer = JSON.parse(localStorage.getItem('customer'));
          if(this.ordering_customer != this.customers_id)
          {
            this.router.navigate(['/customers/products/cart/' + this.ordering_customer]);
          }
          else
          {
            this.router.navigate(['/customers/products/cart/' + this.ordering_customer]);
          }
        }
        else
        {
          alert('Cart is empty. Please add some product to cart.');
          return;
        }
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/login']);
      }

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
