import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Customer } from 'app/models/customer';
import { CustomerService } from 'app/services/customer.service';
import { User } from 'app/models/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit, AfterViewInit {

  loading = false;
  userFromApi: User;
  clicked = false;
  addCustomer: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  fname: string;
  lname: string;
  $email: string;
  $response: any;
  password: string;
  telephone: string;
  address: string;
  company: string;
  suburb: string;
  city: string;
  postcode:string;

  public tableColumns = [
    'customers_ics_id', 'company', 'customers_firstname', 'customers_email_address', 'customers_id', 'login_as_customer'];
  
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    public dataSource = new MatTableDataSource<Customer>();
  
    constructor(
      private formBuilder: FormBuilder,
      private customerService: CustomerService) {
        this.fname = 'fname';
        this.lname = 'lname';
        this.$email = 'email';
        this.telephone = 'telephone';
        this.company = 'company';
        this.address = 'address';
        this.suburb = 'suburb';
        this.city = 'city';
        this.postcode = 'postcode';
        this.addCustomer = this.formBuilder.group(
          {
            fname: ['', [Validators.required]],
            lname: ['', [Validators.required]],
            email: ['', [Validators.required]],
            telephone: ['', [Validators.required]],
            company: ['', [Validators.required]],
            address: ['', [Validators.required]],
            suburb: ['', [Validators.required]],
            city: ['', [Validators.required]],
            postcode: ['', [Validators.required]]
          }
        )
       }
  
    ngOnInit(): void {
      this.loading = true;
      this.loadCustomers();
    }
  
    public loadCustomers = () => {
      this.customerService.getCustomers().subscribe(res => {
        this.dataSource.data = res as Customer[];
        this.loading = false;
      });
    }
    
    AddCustomer(){
      if(this.addCustomer.invalid) {
        return;
      }
      this.submitted = true;
  
      this.loading = true;
      let customer: any = {
        Customers_firstname: this.addCustomer.get(this.fname).value,
        Customers_lastname: this.addCustomer.get(this.lname).value,
        Customers_email_address: this.addCustomer.get(this.$email).value,
        password: 'CID@' + this.addCustomer.get(this.$email).value,
        customers_telephone: this.addCustomer.get(this.telephone).value,
        entry_company: this.addCustomer.get(this.company).value,
        entry_street_address: this.addCustomer.get(this.address).value,
        entry_suburb: this.addCustomer.get(this.suburb).value,
        entry_postcode: this.addCustomer.get(this.postcode).value,
        entry_city: this.addCustomer.get(this.city).value
      };
      this.customerService.AddCustomer(customer)
      .subscribe((data) => {
        let response = data['message'];
        this.$response = response;
        this.addCustomer.reset();
        this.loading = false;
        var modal = document.getElementById('id01');
        setTimeout(() => {
          modal.style.display = "none";
          }, 3000);  //3s
        this.loadCustomers();
      },
      error => {
          this.error = error;
          this.loading = false;
      });
    }

    @HostListener('document:click', ['$event'])
    function(event: MouseEvent) {
      var modal = document.getElementById('id01');
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  
    ngAfterViewInit(): void {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  
    public doFilter = (value: string) => {
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

}
