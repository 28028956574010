import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard',  icon: 'pe-7s-display1', class: '' }
];

export const adminROUTES: RouteInfo[] = [
  { path: '/settings', title: 'Admin Settings',  icon:'fa fa-unlock', class: '' },
  { path: '/healthcheck', title: 'System Health Check',  icon:'fa fa-heartbeat', class: '' }
];

export const psROUTES: RouteInfo[] = [
  { path: '/ps_membership', title: 'Member Renewals',  icon:'pe-7s-add-user', class: '' },
  { path: '/ps_corporate_renewals', title: 'Corporate Renewals',  icon:'pe-7s-id', class: '' },
  { path: '/registrants', title: 'Event Registrants',  icon:'pe-7s-way', class: '' },
];

export const mscROUTES: RouteInfo[] = [
  { path: '/add-invoice', title: 'Invoice',  icon:'pe-7s-news-paper', class: '' },
  { path: '/exchequer-poll', title: 'Exchequer Stats',  icon:'pe-7s-news-paper', class: '' },
];

export const shopROUTES: RouteInfo[] = [
  { path: '/monthlyorders/', title: 'Monthly Orders',  icon: 'pe-7s-cart', class: '' },
  // { path: '/amf', title: 'AMF Renewals',  icon:'pe-7s-note2', class: '' },
  { path: '/customers', title: 'Centres',  icon:'pe-7s-users', class: '' },
  { path: '/orders', title: 'All Orders',  icon:'pe-7s-graph', class: '' },
  { path: '/products', title: 'Products',  icon:'pe-7s-light', class: '' },
  { path: '/salesofstock/', title: 'Sales of Stock',  icon:'pe-7s-graph3', class: '' },
  { path: '/exchequer', title: 'Exchequer',  icon:'pe-7s-calculator', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  adminMenu: any[];
  psMenu: any[];
  mscMenu: any[];
  shopMenu: any[];
  allowed_admin: string;
  showAdminMenu: boolean = false;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {

    this.allowed_admin = this.authenticationService.currentUserValue.email;
    if ((this.allowed_admin == 'ashraful@ics.ie') || (this.allowed_admin == 'john@ics.ie') || (this.allowed_admin == 'declan.munroe@ics.ie') || (this.allowed_admin == 'maciej.gut@skillsbox.com')) 
    {
      this.showAdminMenu = true;
      this.adminMenu = adminROUTES.filter(adminMenu => adminMenu);
    }
    this.psMenu = psROUTES.filter(psMenu => psMenu);
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.mscMenu = mscROUTES.filter(mscMenu => mscMenu);
    this.shopMenu = shopROUTES.filter(shopMenu => shopMenu);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  logout() {
    this.authenticationService.logout();
  }
}
