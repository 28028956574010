import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { CustomersComponent } from './customers/customers.component';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomerComponent } from './customers/customer/customer.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order/order.component';
import { ProductsComponent } from './products/products.component';
import { ProductService } from './services/product.service';
import { OrderService } from './services/order.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SuperuserComponent } from './superuser/superuser.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { CustomersproductsComponent } from './customersproducts/customersproducts.component';
import { AddproductsComponent } from './customersproducts/addproducts/addproducts.component';
import { RemoveproductsComponent } from './customersproducts/removeproducts/removeproducts.component';
import { ProductComponent } from './products/product/product.component';
import { OrderstatushistoryComponent } from './orders/order/orderstatushistory/orderstatushistory.component';
import { ResetComponent } from './login/reset/reset.component';
import { ExchequerComponent } from './exchequer/exchequer.component';
import { MonthlyordersComponent } from './monthlyorders/monthlyorders.component';
import { SettingsComponent } from './settings/settings.component';
import { CouponComponent } from './coupon/coupon.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { NgProgressModule } from 'ngx-progressbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import  {MatSelectModule} from '@angular/material/select';
import { ResponseComponent } from './response/response.component';
import { SalesOfStockComponent } from './sales-of-stock/sales-of-stock.component';
import { SalesofstockRedirectComponent } from './salesofstock-redirect/salesofstock-redirect.component';
import { ShoppingcartComponent } from './shoppingcart/shoppingcart.component';
import {MatBadgeModule} from '@angular/material/badge'
import { AlertModule } from './_alert';
import { Ps2exchequerComponent } from './ps2exchequer/ps2exchequer.component';
import { SkillscardComponent } from './orders/order/skillscard/skillscard.component';
import { Mypipe } from './pipes/date.pipe';
import { HealthcheckComponent } from './healthcheck/healthcheck.component';
import { SearchComponent } from './search/search.component';
import { ExpenseFormComponent } from './expense-form/expense-form.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { InvoiceService } from './services/invoice.service';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceComponent } from './invoices/invoice/invoice.component';
import { PsEventsRegistrantsComponent } from './ps-events-registrants/ps-events-registrants.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AmfComponent } from './amf/amf.component';
import { AmfredirectComponent } from './amfredirect/amfredirect.component';
import { HistoryComponent } from './amf/history/history.component';
import { MatIconModule } from '@angular/material/icon';
import { CancellationComponent } from './orders/cancellation/cancellation.component';
import {MatDialogModule} from "@angular/material/dialog";
import { CancelledComponent } from './orders/cancellation/cancelled/cancelled.component';
import { NotcancelledComponent } from './orders/cancellation/notcancelled/notcancelled.component';
import { CancellationapprovedComponent } from './orders/cancellation/cancellationapproved/cancellationapproved.component';
import { CancellationrequestedComponent } from './orders/cancellation/cancellationrequested/cancellationrequested.component';
import { InvalidrequestComponent } from './orders/cancellation/invalidrequest/invalidrequest.component';
import { StocksupdatedComponent } from './orders/cancellation/stocksupdated/stocksupdated.component';
import { PsRenewalsComponent } from './ps-renewals/ps-renewals.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { CorporateRenewalsComponent } from './corporate-renewals/corporate-renewals.component';
import { OnlyDigitsDirective } from './only-digits.directive';
import { ExchequerPollingComponent } from './exchequer-polling/exchequer-polling.component';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { MoneyDirective } from './money.directive';

@NgModule({
  imports: [
    NgxPaginationModule,
    MatDialogModule,
    MatIconModule,
    MatSlideToggleModule,
    MatExpansionModule,
    AlertModule,
    MatBadgeModule,
    MatSelectModule,
    NgProgressModule,
    MatProgressBarModule,
    ChartsModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    AppRoutingModule,
    MatTableModule,
    CdkTableModule,
    MatFormFieldModule,
    MatSortModule,
    MatInputModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatListModule,
    Ng2SearchPipeModule,
    NgbModule,
  ],

  exports: [
    MatProgressSpinnerModule,
    MatTableModule,
    MatTableModule,
    MatSortModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
  ],
  
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    CustomersComponent,
    CustomerComponent,
    OrdersComponent,
    OrderComponent,
    ProductsComponent,
    SuperuserComponent,
    Mypipe,
    DateAgoPipe,
    CustomersproductsComponent,
    AddproductsComponent,
    RemoveproductsComponent,
    ProductComponent,
    OrderstatushistoryComponent,
    ResetComponent,
    ExchequerComponent,
    MonthlyordersComponent,
    SettingsComponent,
    CouponComponent,
    DashboardComponent,
    ResponseComponent,
    SalesOfStockComponent,
    SalesofstockRedirectComponent,
    ShoppingcartComponent,
    Ps2exchequerComponent,
    SkillscardComponent,
    HealthcheckComponent,
    SearchComponent,
    ExpenseFormComponent,
    InvoicesComponent,
    InvoiceComponent,
    PsEventsRegistrantsComponent,
    AmfComponent,
    AmfredirectComponent,
    HistoryComponent,
    CancellationComponent,
    CancelledComponent,
    NotcancelledComponent,
    CancellationapprovedComponent,
    CancellationrequestedComponent,
    InvalidrequestComponent,
    StocksupdatedComponent,
    PsRenewalsComponent,
    CorporateRenewalsComponent,
    OnlyDigitsDirective,
    ExchequerPollingComponent,
    GenericTableComponent,
    MoneyDirective,
  ],
  providers: [ProductService, OrderService, InvoiceService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
