import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface Label {
  data: string;
  label: string;
}

export interface TableAction {
  itemIdField:string;
  itemActivationField?:string;
  itemDeactivationField?:string;
  actionId:string;
  columnLabel:string;
  cellLabelActive:string;
  cellLabelInactive?:string;
}
export interface TableActionEvent {
  tableId: string;
  actionId: string;
  data?:any;
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {

  // @Input() data: [];   @Input() set centre(newCentre: Centre){
  _data: any;
  // @Input() set data(data){
  //   console.log("new data received:", data);
  //   this._data = data;
  //   this.setData();
  // }

  // @Input() disableSorting: boolean;
  // @Input() disablePagination: boolean;

  @Input() set data(newData:any){
    console.log(`table ${this.tableId} received new data:`, newData);
    this._data = newData;

    if (this._data && this.table && this.dataSource.paginator){
      this.refreshData();
      this.refreshTable();
    } else{
      console.log(`table ${this.tableId} too early to refresh.`);
    }
  }
  @Input() set refreshTrigger(x:any){
    if (this._data && this.table && this.dataSource.paginator){
      this.refreshData();
      this.refreshTable();
    } else{
      console.log(`table ${this.tableId} too early to refresh.`);
    }
  }
  @Input() headers:Label[];
  @Input() pageSizeOptions: number[];
  defaultPageSizeOptions: number[] = [5,20,100];
  @Input() actions: TableAction[];
  @Input() tableId: string;
  @Output() actionTriggered = new EventEmitter<TableActionEvent>();

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[];// = ['id', 'fName', 'lName', 'gender', 'centreName', 'sentTo', 'sentAt'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(private cdRef : ChangeDetectorRef) { }
  ngOnInit(): void {

    this.setData();
    this.setHeaders();
    if (! this.pageSizeOptions) {
      console.log("No pageSizeOptions - using default");
      this.pageSizeOptions = this.defaultPageSizeOptions;
    }

    console.log(`${this.tableId} table initiated`);
    console.log(
      // this.dataSource, 
      this.headers, 
      this.displayedColumns, 
      this.pageSizeOptions, 
      this.actions);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewChecked() {
    // This is to fix
    // ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'true'. Current value: 'false'.
    // The error appears only in the development mode.
    this.cdRef.detectChanges();
  }

  setData(){
    if( Array.isArray(this.actions) && Array.isArray(this._data)) {
      this.actions.forEach( a => 
        this._data.forEach( d => {
          
          (d as Object)[a.actionId] = a.cellLabelActive;
          // console.log(d,a);
        })
      );

      //   this.data.forEach( d => d["d"] = "d")
      //   this.data.push(x.actionId));
      // this.data.push(x.actionId));
    }
    this.dataSource = new MatTableDataSource(this._data);//x;
    // this.dataSource.data = this._data;// = new MatTableDataSource(this._data);//x;
  }
  refreshData(){
    if( Array.isArray(this.actions) && Array.isArray(this._data)) {
      this.actions.forEach( a => 
        this._data.forEach( d => {
          
          (d as Object)[a.actionId] = a.cellLabelActive;
          // console.log(d,a);
        })
      );
      this.dataSource.data = this._data;
    }
  }

  setHeaders(){

    if(Array.isArray(this.actions)) {
      this.actions.forEach( x => 
        this.headers.push({data: x.actionId, label: x.columnLabel} as Label)
      );
    }
    // this.headers.push({data: "sendCert", label: "Send Cert"} as Label)
    let displayedColumns:string[] = [];
    for(const val of this.headers) {
      displayedColumns.push(val.data);
    }
    this.displayedColumns = displayedColumns;
  }


  applyFilter(val:string){

    console.log("applying filter")

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    } else {
      console.log("no paginator on ", this.dataSource);
    }
    // const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = val.trim().toLowerCase();
  }

  refreshTable(){
    console.log("re-rendering table " + this.tableId, this.table);
    this.refreshData();
    this.table.renderRows();
  }

  isAction: boolean = true;
  isActionColumn(columnId:string): boolean {
    // console.log(".");
    // console.log("checking if action: " + columnId);
    if(this.actions.find(x => x.actionId === columnId)) {
      // console.log(`ac DETECTED:¦${columnId}¦`);
      return true;// this.isAction;
    } else {
      // console.log(`not ac:¦${columnId}¦`);
      return false;//! this.isAction;
    }
  }

  getColumnLabel(columnId:string): string {
    // console.log("getColumnLabel, columnId: ", columnId);
    return this.actions.find( x => x.actionId === columnId).columnLabel;
  }
  getCellLabel(item, columnId): string {
    let label = this.actions.find( x => x.actionId === columnId).cellLabelActive;
    if (label= "->") {
      return "<i class='pe-7s-next' style='font-size: 2.7rem;'></i>"
    }
    return label;
  }
  emitTableActionEvent(item, columnId){
    // console.log(row);
    // console.log(columnId);
    // let action = {
    //   tableId: this.tableId,
    //   data:{
    //     id: id
    //   }} as TableActionEvent;
    let id = item[this.actions.find( x => x.actionId === columnId).itemIdField];
    
    this.actionTriggered.emit({
      tableId: this.tableId,
      actionId: this.actions.find(x => x.actionId === columnId).actionId,
      data:{
        id: id,
      }
    });
  }


  test(){
    console.log("actions:", this.actions);
    // this.isAction = ! this.isAction;
    console.log("headers", this.headers);
    // console.log(this.isAction);
    // this.localStorage();
    console.log(this._data);
  }

  // ac DETECTED:¦sentAt¦
  //      not ac:¦sentAt¦
  localStorage(){
    console.log(localStorage.getItem("user"));
  }
}
