import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Corporate_renewals, Corporate_renewalOutbound } from 'app/models/CorporateRenewals';
import { society } from 'app/models/MembershipRenewals';
import { PeopleServerService } from 'app/services/people-server.service';

@Component({
  selector: 'app-corporate-renewals',
  templateUrl: './corporate-renewals.component.html',
  styleUrls: ['./corporate-renewals.component.scss']
})
export class CorporateRenewalsComponent implements OnInit, AfterViewInit {

  loading = false;
  corporate_id: number = 0;
  index: number = 0;
  response: Corporate_renewals;
  data: Corporate_renewals[];
  // public tableColumns = ['corporate_id','title','type','amount','renewed_on','next_renewal','exchequer_ref','created_dt','processed_dt','expush'];
  // public tableColumns = ['corporate_id','title','type','amount','renewed_on','next_renewal','exchequerStockCode','exchequerAccountCode','society','sin','created_dt','processed_dt','expush'];
  public tableColumns = ['renewal_id','corporate_id','title','members_limit','amount','renewed_on','next_renewal','exchequerStockCode','exchequerAccountCode','society','sin','created_dt','processed_dt','expush'];

  testItem: Corporate_renewalOutbound;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;

  public dataSource = new MatTableDataSource<Corporate_renewals>();
  
  constructor(private psService: PeopleServerService) { }

  ngOnInit(): void {
    this.getCorporateRenewals();
  }

  getCorporateRenewals(){
    this.loading = true;
    this.psService.getCorporate_renewals().subscribe(data => {
      this.data = data;
      this.dataSource.data = this.makeHumanReadable(data);
      this.loading = false;
      console.log("CorporateRenewals received: ", this.dataSource.data);
    })
  }

  makeHumanReadable(data: Corporate_renewals[]) : Corporate_renewals[] {
    return data;
  }

  exchequerPush(id:number){

    this.loading = true;

    let item : Corporate_renewals =  this.data.find(x => x.renewal_id == id);

    // construct array of "society" objects based on item.society string.
    //item.society string is formated like so: "grade1:soc.title1,grade2:soc.title2,..." 
    const soc0 : string[] = item.society.split(',');

    let societies : society[] = [];
    soc0.forEach( x => {
      const soc1 : string[] = x.split(":");
      societies.push({
        "grade": soc1[1],
        "society_title": soc1[0]
      } as society)
    });

    // construct the whole outbound object
    let outboundItem : Corporate_renewalOutbound = {
      "corporate_id": item.corporate_id,
      "corporate_name": item.title,//mod
      "exchequerAccountCode": item.exchequerAccountCode,
      "exchequerAltCode": item.corporate_id,//mod
      "dattm": item.processed_dt,//mod
      "expire_date": item.expire_date, // new, pulled from Ps_corporate_renewals.next_renewal
      "renewal_id": item.renewal_id,
      "sin": item.sin,
      "amount": item.amount,
      "exchequerStockCode": item.exchequerStockCode,
      "society": societies
    };

    console.log("outboundItem:",outboundItem);
    this.testItem = outboundItem;


    // let value = event.target.innerHTML;
    // this.corporate_id = value.replace(/<\/?.+?>|Push/ig, '');
    // this.index = this.dataSource.data.findIndex(x => x.corporate_id == this.corporate_id);
    // this.response = this.dataSource.data[this.index];
    // var arr = this.dataSource.data;
    // arr.splice(this.index, 1)
    // this.dataSource.data = arr;
    this.psService.exchequerPushCorporateRenewal(outboundItem).subscribe(
      res => {
        console.log("exchequerPushCorporateRenewal returned:", res);
        let indexOfPushed = this.data.findIndex(x => x.renewal_id == id);

        if(indexOfPushed == -1) {
          console.log(`cannot find element with user_id ==  ${id}`);
        }
        console.log("indexOfPushed: " + indexOfPushed);
  
        this.data.splice(indexOfPushed,1);
        // remap table to data again to ensure that both arrays match each other?
        this.dataSource.data = this.data;
        this.table.renderRows();

        this.loading = false;
      },
      err => {
        console.log("exchequerPushCorporateRenewal returned with error:", err);
      }
    );

    
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

}
