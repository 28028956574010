import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Product } from 'app/models/product';
import { ProductService } from 'app/services/product.service';
import { Observable } from 'rxjs';
import { AddProductsToCustomers } from 'app/models/AddProductsToCustomers';
import { CustomerService } from 'app/services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'app/models/customer';

@Component({
  selector: 'app-addproducts',
  templateUrl: './addproducts.component.html',
  styleUrls: ['./addproducts.component.css']
})
export class AddproductsComponent implements OnInit {

  term;
  form: FormGroup;
  products$: Observable<Product>;
  addCustomerProducts: AddProductsToCustomers;
  public globalResponse: any;
  customers_id: number;
  customer$: Observable<Customer>;
  products_id: number;
  existingProducts: Product;

  constructor(
    private fb: FormBuilder, 
    private productService: ProductService,
    private customerService: CustomerService,
    private avRoute: ActivatedRoute,
    private router: Router
  ) 
  {
    this.form = this.fb.group({
      products: this.fb.array([], [Validators.required])
    });
    const idParam = 'id';
    if (this.avRoute.snapshot.params[idParam]) {
      this.customers_id = this.avRoute.snapshot.params[idParam];
    }
  }

  ngOnInit(): void {
    this.loadProducts();
    this.loadCustomer();
  }
  loadProducts() {
    this.products$ = this.productService.getAvailableCustomersProducts(this.customers_id);
  }

  loadCustomer() {
    this.customer$ = this.customerService.getCustomer(this.customers_id);
  }

  onCheckboxChange(e) {
    const products$: FormArray = this.form.get('products') as FormArray;

    if (e.target.checked) {
      products$.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      products$.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          products$.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    console.log(this.form.value)
    this.productService.addCustomerProducts(this.form.value, this.customers_id).subscribe((result) =>
    {
      this.globalResponse = result;
      this.router.navigate(['/customers/products/', this.customers_id]);
    })
  }
}
