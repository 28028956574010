import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TransactionInProgress, TransactionPosted, UnitSentToEnlight } from 'app/exchequer-polling/exchequer-polling.component';
import { environment } from 'environments/environment.prod';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  counter = 0;

  constructor(private http: HttpClient) { }


  getTransactionsInProgress(): Observable<TransactionInProgress[]>{
    let url: string = `${environment.apiCmsExchequer}pollTransactionsInProgress`;
    return this.http.post<TransactionInProgress[]>(url, this.httpOptions);
  }

  getTransactionsPosted(): Observable<TransactionPosted[]>{
    let url: string = `${environment.apiCmsExchequer}pollTransactionsPosted`;
    return this.http.post<TransactionPosted[]>(url, this.httpOptions);
  }

  getUnitsSentToEnlight(): Observable<UnitSentToEnlight[]> {
    let url: string = `${environment.apiCmsExchequer}pollUnitsSentToEnlight`;

    // if (this.counter == 0) {
    //   this.counter++;
    //   return of(
    //     [{
    //         processed_id : 23525,
    //         centre_id : 141,
    //         order_id : 27487,
    //         orderitem_id : 0,
    //         quantity : 10,
    //         itemtype : "advdiags",
    //         sentToEnlight : true,
    //         processed_dt : "recently"
    //       },
    //       {
    //         processed_id : 23525,
    //         centre_id : 141,
    //         order_id : 27487,
    //         orderitem_id : 0,
    //         quantity : 10,
    //         itemtype : "advdiags",
    //         sentToEnlight : true,
    //         processed_dt : "recently"
    //       }] as UnitSentToEnlight[]);
    // }
        return this.http.post<UnitSentToEnlight[]>(url, this.httpOptions);
    }
  
}
