import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable, throwError } from 'rxjs';
import { Product } from '../models/product';
import { map, catchError, retry } from 'rxjs/operators';
import { AddProductsToCustomers } from 'app/models/AddProductsToCustomers';
import { Customer } from 'app/models/customer';
import { UpdateExchequer } from 'app/models/UpdateExchequer';
import { UpdatePrice } from 'app/models/UpdatePrice';
import { UpdateCustomPrice } from 'app/models/UpdateCustomPrice';

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  myAppUrl: string;
  myApiUrl: string;
  inActiveProducts: string;
  csProdURL: string;
  prodConfigUrl: string;
  associatedCsURL: string;
  addCustomerProdURL: string;
  deleteCustomerProdURL: string;
  availablecustomersproduct: string;
  updateExchequerURL: string;
  updateProductPriceURL: string;
  updateCustomPriceURL: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'api/prod?isActive=1';
    this.prodConfigUrl = 'api/prod/config';
    this.inActiveProducts = 'api/prod?isActive=0';
    this.csProdURL = 'api/customersproducts/';
    this.associatedCsURL = 'api/adminprod/';
    this.addCustomerProdURL = 'api/Customers_products/';
    this.deleteCustomerProdURL = 'api/deleteCustomers_products/';
    this.availablecustomersproduct = 'api/AvailableCsProducts/';
    this.updateExchequerURL = 'api/adminprod/update';
    this.updateProductPriceURL = 'api/adminprod/updateprice'
    this.updateCustomPriceURL = 'api/customers_products/update'
  }

  AddProduct(product) {
    return this.http.post(this.myAppUrl + 'api/prod', JSON.stringify(product), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  getProduct(products_id: number): Observable<Product> {
    return this.http.get<Product>(this.myAppUrl + this.myApiUrl + products_id).pipe(
      map(res => res),
       catchError( this.errorHandler)
      );
  }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.myAppUrl + this.myApiUrl).pipe();
  }

  getProductsConfig(): Observable<Product[]> {
    return this.http.get<Product[]>(this.myAppUrl + this.prodConfigUrl).pipe();
  }

  getInActiveProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.myAppUrl + this.inActiveProducts).pipe();
  }

  getAllProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(this.myAppUrl + this.associatedCsURL).pipe();
  }

  getCustomersProducts(customers_id: number): Observable<Product> {
    return this.http.get<Product>(this.myAppUrl + this.csProdURL + customers_id)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  getAvailableCustomersProducts(customers_id: number): Observable<Product> {
    return this.http.get<Product>(this.myAppUrl + this.availablecustomersproduct + customers_id)
    .pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  addCustomerProducts(customerProducts: AddProductsToCustomers, customers_id:number){
    return this.http.post(this.myAppUrl+ this.addCustomerProdURL + customers_id, customerProducts['products'], this.httpOptions).pipe(
      map(res => res),
      catchError(this.errorHandler)
    );
  }

  removeCustomerProducts(customerProducts: AddProductsToCustomers, customers_id:number){
    return this.http.post(this.myAppUrl+ this.deleteCustomerProdURL + customers_id, customerProducts['products'], this.httpOptions).pipe(
      map(res => res),
      catchError(this.errorHandler)
    );
  }

  getAssociatedCustomers(products_id: number): Observable<Product> {
    return this.http.get<Product>(this.myAppUrl + this.associatedCsURL + products_id).pipe(
      map(res => res),
       catchError( this.errorHandler)
      );
  }

  updateExhchequer(updateExhchequer): Observable<UpdateExchequer>{
    return this.http.post<UpdateExchequer>(this.myAppUrl+ this.updateExchequerURL, JSON.stringify(updateExhchequer), this.httpOptions).pipe(
      map(res => res),
      catchError(this.errorHandler)
    );
  }

  updateProductPrice(updatePrice): Observable<UpdatePrice>{
    return this.http.post<UpdatePrice>(this.myAppUrl+ this.updateProductPriceURL, JSON.stringify(updatePrice), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateProductsActions(p2u){
    return this.http.post(this.myAppUrl + this.associatedCsURL + 'updatePA', JSON.stringify(p2u), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  updateCustomPrice(updateCustomPrice): Observable<UpdateCustomPrice>{
    return this.http.post<UpdateCustomPrice>(this.myAppUrl+ this.updateCustomPriceURL, JSON.stringify(updateCustomPrice), this.httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  addProductToCart(products: any) {
    localStorage.setItem("product", JSON.stringify(products));
  }

  getProductFromCart() {
    return JSON.parse(localStorage.getItem('product'));
  }

  removeAllProductFromCart() {
    return localStorage.removeItem("product");
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
