import { Component, OnInit } from '@angular/core';
import { Product } from 'app/models/product';
import { ProductService } from 'app/services/product.service';
import { OrderService } from 'app/services/order.service';
import { OrderDetails } from 'app/models/OrderDetails';
import {Location} from '@angular/common';
import { Customer } from 'app/models/customer';
import { CustomerService } from 'app/services/customer.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'app/models/user';
import { AuthenticationService } from 'app/services/authentication.service';
import { SharedService } from 'app/services/shared.service';


export interface ProductProperties {
  defaultMinQuantity: number,
  defaultMaxQuantity: number,

  definedProducts: ProductProperty[]
}
export interface ProductProperty {
  productId: number,
  minQuantity: number,
  maxQuantity: number
}

@Component({
  selector: 'app-shoppingcart',
  templateUrl: './shoppingcart.component.html',
  styleUrls: ['./shoppingcart.component.css']
})
export class ShoppingcartComponent implements OnInit {

  cart: string;
  customCart: FormGroup;
  productAddedToCart: Product[];
  allTotal: number;
  // cart_items: any[]; maciej 20210302
  orders_total: any[];
  orderDetail:OrderDetails;
  foundproduct: Product;
  customer$: Observable<Customer>;
  customers_id: number;
  ordering_customer: number;
  customers_name: string;
  CommentsForm: FormGroup;
  CouponForm: FormGroup;
  POForm: FormGroup;
  po: string;
  comments: string;
  userFromApi: User;
  discount: number = 0;
  shipping: number;
  coupon: string;
  subtotal: number;
  submitted = false;
  cartItemCount: number = 0;
  products_id : number;
  error: '';
  sendOrderNotification = true;
  // value = 'ON'; // maciej 20210302

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private productService:ProductService, 
    private orderService:OrderService, 
    private customerService: CustomerService,
    private avRoute: ActivatedRoute,
    private router: Router,
    private sharedService:SharedService,
    private _location: Location) {
      this.po = 'po';
      this.comments = 'comments';
      this.coupon = 'coupon';
      this.cart = 'carts';
      this.customCart = this.formBuilder.group(
        {
          carts: ['', [Validators.required]]
        }
      );
      this.POForm = this.formBuilder.group(
        {
          po: ['', [Validators.required]]
        }
      );
      this.CommentsForm = this.formBuilder.group(
        {
          comments: ['', [Validators.required]]
        }
      );
      this.CouponForm = this.formBuilder.group(
        {
          coupon: ['', [Validators.required]]
        }
      );
      const idParam = 'id';
      if (this.avRoute.snapshot.params[idParam]) {
        this.customers_id = this.avRoute.snapshot.params[idParam];
      }
     }

  ngOnInit(): void {
    
    // if(localStorage.key(1) != null) maciej 20210302
    if(localStorage.getItem('customer') != null)
    {
      this.ordering_customer = JSON.parse(localStorage.getItem('customer'));
      if(this.ordering_customer != this.customers_id)
      {
        this.router.navigate(['/customers/products/cart/' + this.ordering_customer]);
      }
    }
    else
    {
      alert('Cart is empty. Please add some product to cart.');
      this.router.navigate(['/customers/products/' + this.customers_id]);
      return;
    }
    
    this.customerService.getCustomer(this.ordering_customer).subscribe((data) => {
      let customers_name = data.customers_firstname +' '+ data.customers_lastname;
      this.CommentsForm.controls[this.comments].setValue(this.authService.currentUserValue.name + ' placing order on behalf of ' + customers_name)
    });
    this.POForm.controls[this.po].setValue('N/A');

    this.loadCustomer();
    // moving these ProductService one-liners here - maciej 20210303
    this.productAddedToCart = JSON.parse(localStorage.getItem('product'));// this.productService.getProductFromCart();

    // maciej 20210303
    for (let p of this.productAddedToCart) {
      p.products_quantity = this.getMinQuantity(p);
    }
  
    this.productService.removeAllProductFromCart();
    this.productService.addProductToCart(this.productAddedToCart);
    this.calculteAllTotal(this.productAddedToCart);
  }

  getMinQuantity(p:Product):number {
    if (!p) { return this.productProperties.defaultMinQuantity; }
    let def: ProductProperty = this.productProperties.definedProducts.find( x => x.productId == p.products_id);
    if(def) {
      return def.minQuantity;
    }
    return this.productProperties.defaultMinQuantity;
  }
  getMaxQuantity(p:Product):number {
    if (!p) { return this.productProperties.defaultMaxQuantity; }
    let def: ProductProperty = this.productProperties.definedProducts.find( x => x.productId == p.products_id);
    if(def) {
      return def.maxQuantity;
    }
    return this.productProperties.defaultMaxQuantity;
  }
  getMaxQuantityById(id: number){
    return this.getMaxQuantity(
      this.productAddedToCart.find(x => x.products_id == id));
  }
  getMinQuantityById(id: number){
    return this.getMinQuantity(
      this.productAddedToCart.find(x => x.products_id == id));
  }

  productProperties: ProductProperties = {

    defaultMinQuantity: 1,
    defaultMaxQuantity: Number.MAX_SAFE_INTEGER,

    definedProducts: [
      { // Minimum quantity for Digital Citizen, id 783, is 10
        productId: 783,
        minQuantity: 10,
        maxQuantity: Number.MAX_SAFE_INTEGER
      }
    ]
  };


  // maciej 20210302
  // onPercentChange() {
  //   if(this.sendOrderNotification == false){
  //     this.value = 'OFF';
  //   }
  //   else{
  //     this.value = 'ON';
  //   }
  // }

  loadCustomer() {
      this.customer$ = this.customerService.getCustomer(this.ordering_customer);
      this.customerService.getCustomer(this.ordering_customer).subscribe((data) => {
      let customers_name = data.customers_firstname +' '+ data.customers_lastname;
      let customers_id = data.customers_id;
      this.customers_id = customers_id;
      this.customers_name = customers_name;
    });
  };

  calculteAllTotal(allItems:Product[])
  {
    let total=0;
    this.shipping = 0;
    // TODO:
    // 0. bug: do not overwrite shipping in every iteration but count all shipped items of an order
    // 1. for...in iterates the keys of the objects in a collection
    // for...of is the correct approach for iterating the objects themselves
    // 2. allItems[i].shipping is the actuall DB value of 4.50 which is lost and overwritten here (with 4.50)
    // 3. refactor if...if..else if:
    //  a) get rid of nesting (the first "if" is in no way different/special)
    //  b) remove repeated checks for the same value, i.e. check only for "less than" or for "more than" but not both
    //  c) remove else ifs (go for switch or single ifs)
    for (let i in allItems) {
      total = total + (allItems[i].products_quantity * allItems[i].final_price);
      if(allItems[i].shipping > 0)
      {
        if(allItems[i].products_quantity == 1)
        {
          this.shipping = 4.5;
        }
        else if(allItems[i].products_quantity > 1 && allItems[i].products_quantity < 11)
        {
          this.shipping = 11;
        }
        else if(allItems[i].products_quantity > 10 && allItems[i].products_quantity < 31)
        {
          this.shipping = 30;
        }
        else if(allItems[i].products_quantity > 30 && allItems[i].products_quantity < 101)
        {
          this.shipping = 45;
        }
        else if(allItems[i].products_quantity > 100)
        {
          this.shipping = 150;
        }
      }
    }

    this.subtotal = total;
    this.allTotal=total + this.shipping;
  }

  onAddQuantity(product:Product)
  {
    this.productAddedToCart=this.productService.getProductFromCart();
    this.productAddedToCart.find(p=>p.products_id==product.products_id).products_quantity = product.products_quantity+1;
    this.productService.removeAllProductFromCart();
    this.productService.addProductToCart(this.productAddedToCart);
    this.calculteAllTotal(this.productAddedToCart);
  }

  getValue(event:any) {
    let value = event.target.innerHTML;
    this.products_id = value.replace(/<\/?.+?>/ig, '');
  };

  customQuantity()
  {

    console.log(this.customCart.get(this.cart).value);
    this.productAddedToCart=this.productService.getProductFromCart();
    this.productAddedToCart.find(p=>p.products_id==this.products_id).products_quantity = this.customCart.get(this.cart).value;
    this.productService.removeAllProductFromCart();
    this.productService.addProductToCart(this.productAddedToCart);
    this.calculteAllTotal(this.productAddedToCart);
  }

  onRemoveQuantity(product:Product)
  {
    this.productAddedToCart=this.productService.getProductFromCart();
    this.productAddedToCart.find(p=>p.products_id==product.products_id).products_quantity = product.products_quantity-1;
    this.productService.removeAllProductFromCart();
    this.productService.addProductToCart(this.productAddedToCart);
    this.calculteAllTotal(this.productAddedToCart);
  }

  onRemoveProduct(product:Product)
  {
    this.productAddedToCart=this.productService.getProductFromCart();
    this.foundproduct = this.productAddedToCart.find(p=>p.products_id==product.products_id);
    const index = this.productAddedToCart.indexOf(this.foundproduct);
    if (index > -1)
    {
      this.productAddedToCart.splice(index, 1)
    }
    this.productService.addProductToCart(this.productAddedToCart);
    this.cartItemCount = this.productAddedToCart.length;
    this.sharedService.updateCartCount(this.cartItemCount);
    this.calculteAllTotal(this.productAddedToCart);
    this.discount = 0;
  }

  back() {
    // TODO: this does not clear ordered items and the previous page (customersproducts)
    // shows empty card until user tries to again add the items
    this._location.back();
  }

  setFullDiscount(){
    this.discount = this.allTotal;
    this.allTotal = 0;
    console.log(this.discount);
  }

  setDiscount(){
    this.submitted = true;
    var x = this.CouponForm.value;
    if (x['coupon'] > this.allTotal)
    {
      alert("Custom discount must be equal to or less than the TOTAL amount!")
      return;
    }

    this.discount = this.CouponForm.get(this.coupon).value;
    this.allTotal = this.allTotal - this.discount;
  }

  get comment() { return this.CommentsForm.get(this.comments)};
  get PO() { return this.POForm.get(this.po)};
  get coupons() { return this.CouponForm.get(this.coupon)};

  ConfirmOrder()
  {
    if(this.CommentsForm.invalid) {
      console.log("this.CommentsForm.invalid == true, returning.")
      return;
    }

    let orderDetail:any={};

    orderDetail.customers_name = this.customers_name;
    orderDetail.customers_id = this.customers_id;
    orderDetail.comments = this.CommentsForm.get(this.comments).value;/// TODO: ???
    orderDetail.po_number = this.POForm.get(this.po).value;/// TODO: ???
    orderDetail.notification = this.sendOrderNotification;

    this.orders_total= [];

    if (this.discount > 0)
    {
      this.orders_total.push({
        title:'Sub-Total',
        text:'Sub-Total',
        value: this.subtotal,
        class:'ot_subtotal',
        sort_order: 100
      });
      this.orders_total.push({
        title:'Shipping',
        text:'Shipping',
        value: this.shipping,
        class:'ot_shipping',
        sort_order: 200
      });
      this.orders_total.push({
        title:'Admin Discount',
        text:'Admin Discount',
        value: this.discount,
        class:'ot_coupon',
        sort_order: 720
      });
      this.orders_total.push({
        title:'Total',
        text:'Total',
        value: this.allTotal,
        class:'ot_total',
        sort_order: 800
      });
    }
    else
    {
      this.orders_total.push({
        title:'Sub-Total',
        text:'Sub-Total',
        value: this.subtotal,
        class:'ot_subtotal',
        sort_order: 100
      });
      this.orders_total.push({
        title:'Shipping',
        text:'Shipping',
        value: this.shipping,
        class:'ot_shipping',
        sort_order: 200
      });
      this.orders_total.push({
        title:'Total',
        text:'Total',
        value: this.allTotal,
        class:'ot_total',
        sort_order: 800
      });
    }
    orderDetail.orders_Totals=this.orders_total;

    // TODO: I have no guarantee now that items in this array are not accessed by indices
    // (as it was the case with localStorage) so I won't refactor but the above if-else be changed to:

    // orderDetail.orders_Totals = [];

    // orderDetail.orders_Totals.push({
    //   title:'Sub-Total',
    //   text:'Sub-Total',
    //   value: this.subtotal,
    //   class:'ot_subtotal',
    //   sort_order: 100
    // });
    // orderDetail.orders_Totals.push({
    //   title:'Shipping',
    //   text:'Shipping',
    //   value: this.shipping,
    //   class:'ot_shipping',
    //   sort_order: 200
    // });
    // orderDetail.orders_Totals.push({
    //   title:'Total',
    //   text:'Total',
    //   value: this.allTotal,
    //   class:'ot_total',
    //   sort_order: 800
    // });

    // if (this.discount > 0)
    // {
    //  orderDetail.orders_Totals.push({
    //     title:'Admin Discount',
    //     text:'Admin Discount',
    //     value: this.discount,
    //     class:'ot_coupon',
    //     sort_order: 720
    //   });
    // }

    orderDetail.cart_Items = [];
    for (let i in this.productAddedToCart) {
      orderDetail.cart_Items.push({
        products_id: this.productAddedToCart[i].products_id,
        products_name: this.productAddedToCart[i].products_name,
        products_price: this.productAddedToCart[i].final_price,
        products_quantity: this.productAddedToCart[i].products_quantity
      }) ;
    }

    // got rid of the class field that was used solely to build orderDetail.cart_Items
    // see the original code below - maciej 20210302
    //   this.cart_items=[];
    //   for (let i in this.productAddedToCart) {
    //     this.cart_items.push({
    //       products_id: this.productAddedToCart[i].products_id,
    //       products_name: this.productAddedToCart[i].products_name,
    //       products_price: this.productAddedToCart[i].final_price,
    //       products_quantity: this.productAddedToCart[i].products_quantity
    //     }) ;
    //  }
    //   orderDetail.cart_Items=this.cart_items;


    console.log("order:", orderDetail);
    // console.log("early return from src/app/shoppingcart/shoppingcart.component.ts/ConfirmOrder()"); return;
    

    this.orderService.PlaceOrder(orderDetail).subscribe((data) => {
      localStorage.removeItem("customer");
      localStorage.removeItem("product");
      this.productAddedToCart=this.productService.getProductFromCart();
      this.sharedService.updateCartCount(this.cartItemCount = 0);
      this.calculteAllTotal(this.productAddedToCart);
      console.log(data);
      this.CommentsForm.reset();
      this.router.navigate(['/orders/', data['orders_id']]);
    },
    error => {
      this.error = error;
    });
  }
}
