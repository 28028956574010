import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { EventRegistration } from 'app/models/EventRegistration';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExchequerResponse } from 'app/models/ExchequerResponse';
import { MembershipRenewals } from 'app/models/MembershipRenewals';
import { isThisTypeNode } from 'typescript';
import { Corporate_renewals, Corporate_renewalOutbound } from 'app/models/CorporateRenewals';

@Injectable({
  providedIn: 'root'
})
export class PeopleServerService {

  myAppUrl: string;
  myApiUrl: string;
  membersUrl: string;
  corporate_renewalsUrl: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  constructor(private http: HttpClient) {
    this.myAppUrl = environment.appUrl;
    this.myApiUrl = 'ps/registrants/';
    this.membersUrl = 'ps/membership';
    this.corporate_renewalsUrl = 'ps/corporaterenewals'
  }

  getRegistrants(): Observable<EventRegistration[]> {
    return this.http.get<EventRegistration[]>(this.myAppUrl + this.myApiUrl).pipe();
  }

  getMembers(): Observable<MembershipRenewals[]> {
    return this.http.get<MembershipRenewals[]>(this.myAppUrl + this.membersUrl).pipe();
  }

  getCorporate_renewals(): Observable<Corporate_renewals[]> {
    return this.http.get<Corporate_renewals[]>(this.myAppUrl + this.corporate_renewalsUrl).pipe();
  }

  exchequerPush(exchequerPush){

    // console.log("would push:", exchequerPush);
    // return of({
    //   cart_order_id : "dupa",
    //   status : "blada"
    // });

    // return this.http.post('https://cms.ics-skills.net/api/exchequer/postExchequerEventAttendeeInvoice', JSON.stringify(exchequerPush), this.httpOptions).pipe(
    return this.http.post(`${environment.apiCmsExchequer}postExchequerEventAttendeeInvoice`, JSON.stringify(exchequerPush), this.httpOptions).pipe(
      map(data => {
        return data as ExchequerResponse;
      })
    );
  }

  exchequerPostMemberInvoice(payload: string){
    console.log("PeopleServerService.exchequerPostMemberInvoice POSTing to https://cms.ics-skills.net/api/exchequer/postExchequerMemberInvoice", payload);
    // return this.http.post('https://cms.ics-skills.net/api/exchequer/postExchequerMemberInvoice', payload, this.httpOptions).pipe(
    return this.http.post(`${environment.apiCmsExchequer}postExchequerMemberInvoice`, payload, this.httpOptions).pipe(
      map(data => {
        return data;// as ExchequerResponse;
      })
    );
  }

  exchequerPushCorporateRenewal(payload : Corporate_renewalOutbound) : Observable<any>{

    return this.http.post<any>(
      `${environment.apiCmsExchequer}postExchequerCorporateRenewalInvoice`, 
      JSON.stringify(payload),
      this.httpOptions
    );

    // return of(true);

  }

}
