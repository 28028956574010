import { environment } from './../../environments/environment.prod';
import { Admin, GetAdmin } from './../models/Admin';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'app/models/user';
import { PasswordReset } from 'app/models/PasswordReset';
import { Reset } from 'app/models/Reset';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      };
    private currentUserSubject: BehaviorSubject<Admin>;
    public currentUser: Observable<Admin>;
    myAppUrl: string;

    constructor(private http: HttpClient) {
        this.myAppUrl = environment.appUrl;
        this.currentUserSubject = new BehaviorSubject<Admin>(JSON.parse(localStorage.getItem('current_user')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Admin {
        return this.currentUserSubject.value;
    }

    getAdmins() {
    return this.http.get<GetAdmin[]>(`${environment.appUrl}admins`).pipe(
        map(res => {
            return res;
        }));
    }

    AddAdmins(admin) {
        return this.http.post(this.myAppUrl + 'admins/register', JSON.stringify(admin), this.httpOptions).pipe(
          map(res => {
            return res;
          })
        );
      }

    login(Admin_email_address: string, password: string) {
        return this.http.post<any>(`${environment.appUrl}admins/authenticate`,
        { Admin_email_address, password })
            .pipe(map(admin => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('current_user', JSON.stringify(admin));
                this.currentUserSubject.next(admin);
                return admin;
            }));
    }

    forgotpassword(username: string) {
        return this.http.post<any>(`${environment.appUrl}admins/forgot`, 
        { username })
            .pipe(map(admin => {
                return admin;
            }));
    }

    passwordreset(reset): Observable<PasswordReset> {
        return this.http.post<PasswordReset>(`${environment.appUrl}admins/resetbylink`, JSON.stringify(reset), this.httpOptions)
            .pipe(map(admin => {
                return admin;
            }));
    }

    reset(reset): Observable<Reset> {
        return this.http.post<Reset>(`${environment.appUrl}admins/reset`, JSON.stringify(reset), this.httpOptions)
            .pipe(map(admin => {
                return admin;
            }));
    }

    resetAdminPassword(reset){
        return this.http.post(`${environment.appUrl}admins/adminpasswordreset`, JSON.stringify(reset), this.httpOptions)
        .pipe(
            map(res => {
                return res;
            }));
    }

    logout() {
        localStorage.removeItem('current_user');
        localStorage.removeItem('customer');
        localStorage.removeItem('product');
        this.currentUserSubject.next(null);
    }

    SuperUserlogin(Customers_email_address : string) {
        return this.http.post<any>(`${environment.appUrl}superusers/authenticate`,
        { Customers_email_address })
            .pipe(map(customer => {
                // store customer jwt token in local storage for logging into shop
                // localStorage.setItem('access_token', customer['access_token']);
                // this.currentUserSubject.next(customer);
                return customer;
            }));
    }

}
