// import { Directive } from '@angular/core';

// @Directive({
//   selector: '[appMoney]'
// })
// export class MoneyDirective {

//   constructor() { }

// }


import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appMoney]'
})
export class MoneyDirective {

  constructor(private el: ElementRef) {
    
    
    // console.log("maxAmount:", this.maxAmount);
    // console.log("maxAmount type:", typeof this.maxAmount);
    // if(!this.maxAmount) {
    //   console.log("no maxAmount, setting maxAmount to 2.50");
    //   this.maxAmount = 2.50;
    // }

  }

  // @HostListener('mouseenter') onMouseEnter() {
  //   this.highlight('yellow');
  // }

  // @HostListener('mouseleave') onMouseLeave() {
  //   this.highlight(null);
  // }
  @Input() maxAmount: number;

  @Output() valueChange = new EventEmitter()

  @HostListener('input', ['$event']) onInputChange(event) {

    
    const originalValue = this.el.nativeElement.value;
    
    //only digits & dots
    let processedValue:string = originalValue.replace(/[^0-9.]*/g, '');

    //remove all starting zeros
    //processedValue = processedValue.replace(/^0+/, '');

    //keep only first dot
    processedValue = this.keepFirstDotOnly(processedValue);

    //keep max 2 chars after first dot
    processedValue = this.max99cents(processedValue); 

    //keep value at or below maxAmound
    if(!this.maxAmount) {
      console.log("no maxAmount");
      this.maxAmount = Number.MAX_SAFE_INTEGER / 2;
    }
    processedValue = this.noMoreThan(processedValue, this.maxAmount);




    this.el.nativeElement.value = processedValue;
    this.valueChange.emit(processedValue);

    if ( originalValue !== this.el.nativeElement.value ) {
      event.stopPropagation();
    }

    console.log({originalValue}, {processedValue});
  }


  private keepFirstDotOnly(str:string) {
    let dotFound = false;
		for (let i = 0; i < str.length; i++) {

			if (str[i] === '.') {
        if (dotFound) {
          str = str.slice(0,i) + str.slice(i+1);
          --i;
        } else {
          dotFound = true;
        }
			}

		}
    return str;
  }

  private max99cents(str:string) {

    let indexOfDot = Number.MAX_SAFE_INTEGER / 2;

    for (let i = 0; i < str.length; i++) {
      if (str[i]==='.') {
        indexOfDot = i;
        break;
      }
    }

    if (str.length > indexOfDot + 3) {
      str = str.slice(0, indexOfDot + 3)
    }
    
    return str;
  }

  private noMoreThan(str:string, maxAmount: number) {

    let result = Number(str);

    if (Number.isNaN(result)) {
      console.log("MoneyDirective is unable to parse " + str);
      return str;
    }
    
    if(maxAmount < result) {
      return maxAmount.toString();
    }
    return str;
  }


  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;
  }

}
