import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CancelledComponent } from './cancelled/cancelled.component';
import { NotcancelledComponent } from './notcancelled/notcancelled.component';
import { CancellationapprovedComponent } from './cancellationapproved/cancellationapproved.component';
import { OrderService } from 'app/services/order.service';
import { InvalidrequestComponent } from './invalidrequest/invalidrequest.component';
import { StocksupdatedComponent } from './stocksupdated/stocksupdated.component';

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss']
})
export class CancellationComponent implements OnInit {

  accept: string;
  id: number;
  last_updated : Date;
  expiry_time : Date;
  status: number;
  loading = false;
  // after this time the cancellation request expires (cannot be accepted or rejected anymore)
  hoursValid = 240; 

  constructor(
    private avRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private orderService: OrderService
  ) {
    const Param = 'value'; 
    const idParam = 'id';
    if (this.avRoute.snapshot.params[Param]) {
      this.accept = this.avRoute.snapshot.params[Param];
    }
    if (this.avRoute.snapshot.params[idParam]) {
      this.id = this.avRoute.snapshot.params[idParam];
    }
  }

  ngOnInit(){
    this.loading = true;
    if(this.accept == 'true'){
      this.orderService.getOrder(this.id).subscribe((data => {
        this.last_updated = new Date(data.last_modified);
        this.expiry_time = new Date(this.last_updated.setHours(this.hoursValid));
        this.status = data.orders_status;
        if(new Date() > this.expiry_time || this.status != 13)
        {
          this.dialog.open(InvalidrequestComponent);
          this.router.navigate(['/']);
        }
        else{
          this.orderService.OrderCancellationAccepted(this.id).subscribe((data => {
            this.router.navigate(['orders/' + this.id]);
            console.log(JSON.stringify(data));
            this.dialog.open(CancelledComponent);
          }))
        }
      }))
    }
    else if(this.accept == 'false'){
      this.orderService.getOrder(this.id).subscribe((data => {
        this.last_updated = new Date(data.last_modified);
        this.expiry_time = new Date(this.last_updated.setHours(this.hoursValid));
        this.status = data.orders_status;
        if(new Date() > this.expiry_time || this.status != 13)
        {
          this.dialog.open(InvalidrequestComponent);
          this.router.navigate(['/']);
        }
        else{
          this.orderService.OrderCancellationRejected(this.id).subscribe((data => {
            console.log(JSON.stringify(data));
            this.router.navigate(['orders/' + this.id]);
            this.dialog.open(NotcancelledComponent);
          }))
        }
      }))
    }
    else if(this.accept == 'cancelled'){
      this.orderService.getOrder(this.id).subscribe((data => {
        this.status = data.orders_status;
        if(this.status == 14){
          this.orderService.OrderCancelled(this.id).subscribe((data => {
          console.log(JSON.stringify(data));
          this.router.navigate(['orders/' + this.id]);
          this.dialog.open(CancellationapprovedComponent);
          }))
        }
        else{
          this.dialog.open(InvalidrequestComponent);
          this.router.navigate(['/']);
        }
      }))
    }
    else if(this.accept == 'stocksupdated'){
      this.orderService.getOrder(this.id).subscribe((data => {
        this.status = data.orders_status;
        if(this.status == 15){
          this.orderService.StocksUpdated(this.id).subscribe((data => {
          console.log(JSON.stringify(data));
          this.router.navigate(['orders/' + this.id]);
          this.dialog.open(StocksupdatedComponent);
          }))
        }
        else{
          this.dialog.open(InvalidrequestComponent);
          this.router.navigate(['/']);
        }
      }))
    }
  }
}
